/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from "react";
import { useBreakpoints } from "react-breakpoints-hook";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Milliman from "../../useFetch/millimanFetch/millimanFetch";
import Auth from "../../utils/auth";
import formatMoney from "../../utils/formatMoney";
import useScreenSize from "../../utils/useScreenSize";
import Button from "../button/Button";
import CurrentPlanCard from "../cards/currentPlanCard";
import LoadingSkeleton from "../loadingSkeleton/loadingSkeleton";
import Table from "../table/table";
import Tooltip from "../tooltips/tooltipLogic";
import tooltipObject from "../tooltips/tooltipObject";
import DashboardExhibitContainer from "./dashboardExhibitContainer";
import ContactInformationForm from "../forms/addContactInformation";
import useProgress from "../../hooks/useProgress";

export default function PlanRankTable({ setFetchStatus, dashboardView }) {
  const [planRanks, setPlanRanks] = useState([]);
  const [planRankTitleTooltip, setPlanRankTitleTooltip] = useState(false);
  const [planHeaderTooltip, setPlanHeaderTooltip] = useState(false);
  const [carrierHeaderTooltip, setCarrierHeaderTooltip] = useState(false);
  const [premiumHeaderTooltip, setPremiumHeaderTooltip] = useState(false);
  const [pharmacyHeaderTooltip, setPharmacyHeaderTooltip] = useState(false);
  const [medicalHeaderTooltip, setMedicalHeaderTooltip] = useState(false);
  const [totalHeaderTooltip, setTotalHeaderTooltip] = useState(false);
  const [costCompareTooltip, setCostCompareTooltip] = useState(false);
  const [status, setStatus] = useState("loading");
  const [loadCount, setLoadCount] = useState(10);
  const [renderedArray, setRenderedArray] = useState([]);
  const { progress, resetProgress, completeProgress } = useProgress();
  // const [contactSubmitted, setContactSubmitted] = useState(false);

  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const userToken = Auth.getToken();
  const user = Auth.getUser();
  // const drugStatus = localStorage.getItem("drugListStatus");

  const loadMore = () => {
    // Increase the count by 10 on each click
    setLoadCount((prevCount) => prevCount + 10);
  };

  const redirectToPlanDetails = (planArray, bidID) => {
    // build the endpoint and search params
    const endpoint = "/planDetails";
    // tracking
    Mixpanel("Plan Details Page");
    ReactGATracking("Page View", "Plan Details Page");
    ReactPixelInitialize("Plan Details Page", { value: "none" });
    const queryParams = {
      name: planArray[1],
      carrier: planArray[2],
      planType: planArray[3],
      network: planArray[4],
      totalEstCost: planArray[5],
      premium: planArray[6],
      medicalPremium: planArray[7],
      pharmacyPremium: planArray[8],
      estMedCost: planArray[9],
      estPharmCost: planArray[10],
      monthlyPremium: planArray[11],
      estMonthlyMedCost: planArray[12],
      estMonthlyPharmCost: planArray[13],
      msaDeposit: planArray[14],
      suppValue: planArray[15],
      netImpact: planArray[16],
      hNumber: bidID.split("_")[0],
      planIdentifier: bidID.split("_")[1]
    };
    // the base url is required to construct a URL object, so we use example.com as place holder. it won't be used in the final url
    const url = new URL(endpoint, "https://example.com");

    Object.keys(queryParams).forEach((key) => {
      url.searchParams.append(key, queryParams[key]);
    });

    const finalURL = url.pathname + url.search;
    navigate(finalURL);
  };

  const { sm, md } = useBreakpoints({
    sm: { min: 0, max: 640 },
    md: { min: 641, max: 1400 }
  });

  useEffect(() => {
    const fetchPlanRanks = async () => {
      if (!Auth.loggedIn()) return;
      resetProgress();

      try {
        const retrievedPlanRanks = await Milliman.trigger(userToken);
        if (retrievedPlanRanks === "failed" || retrievedPlanRanks === "missing") {
          setFetchStatus((prevState) => ({ ...prevState, planRanks: retrievedPlanRanks }));
          setStatus(retrievedPlanRanks);
          completeProgress();
          return;
        }
        setPlanRanks(retrievedPlanRanks);
        setStatus("complete");
        completeProgress();
      } catch (err) {
        console.error("Error fetching plan ranks:", err);
        setStatus("failed");
        completeProgress();
      }
    };

    fetchPlanRanks();
  }, []);

  useEffect(() => {
    setRenderedArray(planRanks.slice(0, loadCount));
  }, [planRanks, loadCount]);

  const createContactAndTriggerRanks = async (event, formValues) => {
    event.preventDefault();
    if (!Auth.loggedIn()) return;

    resetProgress();
    setStatus("loading");

    const contactData = { ...formValues, userId: user.data.id, email: user.data.email };
    try {
      await Milliman.createMillimanContact(userToken, contactData);
      await Milliman.reTriggerPlanRanks(userToken);

      const retrievedPlanRanks = await Milliman.trigger(userToken);

      if (retrievedPlanRanks === "failed" || retrievedPlanRanks === "missing") {
        setFetchStatus((prevState) => ({ ...prevState, planRanks: retrievedPlanRanks }));
        setStatus(retrievedPlanRanks);
        completeProgress();
        return;
      }
      setPlanRanks(retrievedPlanRanks);
      setStatus("complete");
      completeProgress();
    } catch (err) {
      console.error("Error submitting contact information or re-triggering plan ranks:", err);
      completeProgress();
    }
  };

  const renderTableData = () => {
    if (status === "loading") {
      const loadingSkeletonArray = new Array(7);
      if (!dashboardView) {
        loadingSkeletonArray.fill([
          <LoadingSkeleton className="mt-2" key="1" />,
          <LoadingSkeleton className="mt-2" key="2" />,
          <LoadingSkeleton className="mt-2" key="3" />,
          <LoadingSkeleton className="mt-2" key="4" />,
          <LoadingSkeleton className="mt-2" key="5" />,
          <LoadingSkeleton className="mt-2" key="6" />
        ]);
      } else {
        loadingSkeletonArray.fill([
          <LoadingSkeleton className="mt-2" key="1" />,
          <LoadingSkeleton className="mt-2" key="2" />
        ]);
      }
      return loadingSkeletonArray;
    }
    if (status === "complete") {
      const medicareOptionsToMap = dashboardView ? renderedArray.slice(0, 5) : renderedArray;

      const graphBar = (plan) => {
        if (!planRanks.length) return null;

        const netImpacts = planRanks.slice(0, 5).map((p) => p.netImpact);
        const lowestCost = Math.min(...netImpacts);

        // Convert planCost to number safely
        const planCost = parseFloat(plan.replace("$", "").replace(",", ""));

        // Find the maximum cost among the top 5 plans
        const maxCost = Math.max(
          ...planRanks
            .slice(0, 5)
            .map((p) => parseFloat(p.columns[5].replace("$", "").replace(",", "")))
        );

        // Set min and max width constraints
        let MAX_WIDTH;
        if (sm) {
          MAX_WIDTH = 150;
        } else if (md) {
          MAX_WIDTH = 200;
        } else {
          MAX_WIDTH = 250;
        }
        const MIN_WIDTH = 50;

        // Ensure denominator is not zero
        const costRange = maxCost - lowestCost || 1; // Avoid division by zero

        // Normalize width to scale proportionally
        const normalizedWidth =
          ((planCost - lowestCost) / costRange) * (MAX_WIDTH - MIN_WIDTH) + MIN_WIDTH;

        // Ensure the width stays within the limits
        const finalWidth = Math.max(MIN_WIDTH, Math.min(normalizedWidth, MAX_WIDTH));

        return (
          <div className="relative flex justify-start w-full max-w-[250px]">
            {/* Cost Bar */}
            <div
              style={{ width: `${finalWidth}px` }}
              className="h-5 rounded-lg bg-thirdAccentColor transition-all duration-300 shadow-md"
            />
          </div>
        );
      };
      if (!dashboardView) {
        return medicareOptionsToMap.map((plan) => [
          <div onClick={() => redirectToPlanDetails(plan.columns, plan.bidId)}>
            {" "}
            {plan.columns[1]}
          </div>,
          plan.columns[2],
          <>
            {plan.columns[6]} ({plan.columns[11]}/month)
          </>,
          <>
            {plan.columns[10]} ({plan.columns[13]}/month)
          </>,
          <>
            {plan.columns[9]} ({plan.columns[12]}/month)
          </>,
          <>
            {plan.columns[5]} ({formatMoney.extractMonthlyPayment(plan.columns[5])}/month)
          </>
        ]);
      }
      if (screenSize.width >= 1000) {
        return medicareOptionsToMap.map((plan) => [
          <div onClick={() => redirectToPlanDetails(plan.columns, plan.bidId)}>
            {" "}
            {plan.columns[1]}
          </div>,
          <Button onClick={() => redirectToPlanDetails(plan.columns, plan.bidId)}>
            Learn More
          </Button>,
          <div>
            {plan.columns[5]} ({formatMoney.extractMonthlyPayment(plan.columns[5])}/month){" "}
          </div>,
          <span>{graphBar(plan.columns[5])}</span>
        ]);
      }
      if (screenSize.width < 1000) {
        return medicareOptionsToMap.map((plan) => [
          <div onClick={() => redirectToPlanDetails(plan.columns, plan.bidId)}>
            {" "}
            <span className="mx-1 -my-1"> {plan.columns[1]} </span>
            {graphBar(plan.columns[5])}
            <span className="mx-2 flex justify-end -mb-8">
              {plan.columns[5]}({formatMoney.extractMonthlyPayment(plan.columns[5])}/month){" "}
              <Button
                className="ml-12"
                onClick={() => redirectToPlanDetails(plan.columns, plan.bidId)}>
                Learn More
              </Button>
            </span>
          </div>
        ]);
      }
    }
    return null;
  };

  const renderTableHeaders = () => {
    if (!dashboardView) {
      return [
        <div className="whitespace-nowrap">
          Plan
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setPlanHeaderTooltip(true)}
            onMouseLeave={() => setPlanHeaderTooltip(false)}
          />
          {planHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.planHeader}
              size="small"
            />
          )}
        </div>,
        <div className="whitespace-nowrap">
          Carrier
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setCarrierHeaderTooltip(true)}
            onMouseLeave={() => setCarrierHeaderTooltip(false)}
          />
          {carrierHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.carrierHeader}
              size="beneathInline"
            />
          )}
        </div>,
        <div className="whitespace-nowrap">
          Premium
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setPremiumHeaderTooltip(true)}
            onMouseLeave={() => setPremiumHeaderTooltip(false)}
          />
          {premiumHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.premiumHeader}
              size="beneathInline"
            />
          )}
        </div>,
        <div className="whitespace-nowrap">
          Pharmacy
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setPharmacyHeaderTooltip(true)}
            onMouseLeave={() => setPharmacyHeaderTooltip(false)}
          />
          {pharmacyHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.pharmacyHeader}
              size="beneathInline"
            />
          )}
        </div>,
        <div className="whitespace-nowrap">
          Medical
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setMedicalHeaderTooltip(true)}
            onMouseLeave={() => setMedicalHeaderTooltip(false)}
          />
          {medicalHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.medicalHeader}
              size="beneathInline"
            />
          )}
        </div>,
        <div className="whitespace-nowrap">
          Total Cost
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setTotalHeaderTooltip(true)}
            onMouseLeave={() => setTotalHeaderTooltip(false)}
          />
          {totalHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.totalHeader}
              size={sm ? "beneathInline" : "large"}
            />
          )}
        </div>
      ];
    }
    return [
      <div className="whitespace-nowrap">
        Plan
        <FaRegCircleQuestion
          className="text-sm ml-2"
          onMouseOver={() => setPlanHeaderTooltip(true)}
          onMouseLeave={() => setPlanHeaderTooltip(false)}
        />
        {planHeaderTooltip && (
          <Tooltip
            className="tooltipContainer flex items-center"
            text={tooltipObject.planHeader}
            size="small"
          />
        )}
      </div>,
      <div className="whitespace-nowrap" />,
      <div className="whitespace-nowrap">
        Total Cost
        <FaRegCircleQuestion
          className="text-sm ml-2"
          onMouseOver={() => setTotalHeaderTooltip(true)}
          onMouseLeave={() => setTotalHeaderTooltip(false)}
        />
        {totalHeaderTooltip && (
          <Tooltip
            className="tooltipContainer flex items-center"
            text={tooltipObject.totalHeader}
            size={sm ? "beneathInline" : "large"}
          />
        )}
      </div>,
      <div className="whitespace-nowrap">
        Cost Compare
        <FaRegCircleQuestion
          className="text-sm ml-2"
          onMouseOver={() => setCostCompareTooltip(true)}
          onMouseLeave={() => setCostCompareTooltip(false)}
        />
        {costCompareTooltip && (
          <Tooltip
            className="tooltipContainer flex items-center"
            text={tooltipObject.costCompare}
            size={sm ? "beneathInline" : "large"}
          />
        )}
      </div>
    ];
  };

  return (
    <div className="w-full">
      {status === "complete" && planRanks.length > 0 ? (
        <div>
          <DashboardExhibitContainer
            title={
              <div>
                Medicare Options
                <FaRegCircleQuestion
                  className="text-sm mb-2 ml-2 "
                  onMouseOver={() => setPlanRankTitleTooltip(true)}
                  onMouseLeave={() => setPlanRankTitleTooltip(false)}
                />
                {planRankTitleTooltip && (
                  <Tooltip
                    className="tooltipContainer flex items-center"
                    text={tooltipObject.planRankTableTitle}
                    size="beneathInline"
                  />
                )}
              </div>
            }
            subtitle="We found the best health plans for you based on your health history, prescription history and zip code."
            viewAll={dashboardView}
            viewAllCount={planRanks.length}
            viewAllParam="/medicareOptions">
            <Table
              tableDivClass="universalTableDivClass"
              tableClass={`${dashboardView ? "planRankMobileDashboard" : "planRankMobile"} ${
                !planRanks.length && "noDataHeaders"
              } universalTable`}
              headers={renderTableHeaders()}
              data={renderTableData()}
            />
            {!dashboardView && planRanks.length > loadCount && (
              <Button
                className="rounded-b-md rounded-t-none text-base"
                fullWidth
                onClick={loadMore}>
                Load more...
              </Button>
            )}
            <CurrentPlanCard />
          </DashboardExhibitContainer>
        </div>
      ) : null}
      {status === "missing" || status === "failed" ? (
        <div>
          <DashboardExhibitContainer
            title={
              <div>
                Medicare Options
                <FaRegCircleQuestion
                  className="text-sm mb-2 ml-2"
                  onMouseOver={() => setPlanRankTitleTooltip(true)}
                  onMouseLeave={() => setPlanRankTitleTooltip(false)}
                />
                {planRankTitleTooltip && (
                  <Tooltip
                    className="tooltipContainer flex items-center"
                    text={tooltipObject.planRankTableTitle}
                    size="beneathInline"
                  />
                )}
              </div>
            }
            className="w-100">
            <ContactInformationForm submitContactInformation={createContactAndTriggerRanks} />
          </DashboardExhibitContainer>
        </div>
      ) : null}
      {status === "loading" && (
        <div className="flex flex-col items-center justify-center w-full py-8">
          <DashboardExhibitContainer
            title={
              <div className="flex items-center justify-center">
                Medicare Options
                <FaRegCircleQuestion
                  className="text-sm ml-2"
                  onMouseOver={() => setPlanRankTitleTooltip(true)}
                  onMouseLeave={() => setPlanRankTitleTooltip(false)}
                />
                {planRankTitleTooltip && (
                  <Tooltip
                    className="tooltipContainer flex items-center"
                    text={tooltipObject.planRankTableTitle}
                    size="beneathInline"
                  />
                )}
              </div>
            }
            className="w-full max-w-md text-center">
            <div className="flex flex-col items-center">
              <p className="text-sm text-gray-600 mb-2">Loading Plans...</p>
              <div className="w-64 h-4 bg-gray-300 rounded-full overflow-hidden relative flex items-center">
                <div
                  className="absolute top-0 left-0 h-full bg-thirdAccentColor transition-all mainTextColor flex items-center justify-center text-white font-semibold"
                  style={{ width: `${progress}%` }}>
                  <span className="text-xs w-full text-center">{progress}%</span>
                </div>
              </div>
            </div>
          </DashboardExhibitContainer>
        </div>
      )}
    </div>
  );
}
