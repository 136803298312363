import React, { useState, useEffect } from "react";
import { TbArrowsShuffle } from "react-icons/tb";
import toast from "react-hot-toast";
import Auth from "../../utils/auth";
import patientFetches from "../../useFetch/userFetch/patientFetches";
import DashboardHeader from "../../components/dashboardHeader/DashboardHeader";
import RxInteractions from "../../components/dashboardExhibit/rxInteractions";
import rxInteractionFetch from "../../useFetch/rxInteractionFetch/rxInteractionFetch";

export default function RxInteractionsDetails() {
  const [patientDrugList, setPatientDrugList] = useState([]);
  const [fetchStatus, setFetchStatus] = useState("pending");
  const [interactionsCount, setInteractionsCount] = useState(0);
  useEffect(() => {
    (async function initialPatientFetch() {
      if (Auth.loggedIn()) {
        const userToken = Auth.getToken();
        try {
          const patientDrugListFetchData = await patientFetches.getPatientDrugs(userToken);
          // eslint-disable-next-line prettier/prettier
          const drugInteractionsFetchArray = await rxInteractionFetch.getDrugInteractions(userToken);
          setPatientDrugList(patientDrugListFetchData);
          setInteractionsCount(drugInteractionsFetchArray.length);
          setFetchStatus("success");
        } catch (error) {
          console.error(error);
          setFetchStatus("failed");
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (fetchStatus === "failed") {
      toast.error("There was an error retrieving some of your data. Please try again later.");
    }
  }, [fetchStatus]);

  return (
    <div className="min-h-screen">
      <DashboardHeader icon={<TbArrowsShuffle className="sm:pr-6" />} title="Rx Interactions">
        <p className="text-grayColor">Below is a full view of your Rx Interactions.</p>
      </DashboardHeader>
      <div className="mt-2">
        <RxInteractions
          dashboardView={false}
          interactionsCount={interactionsCount}
          patientDrugList={patientDrugList}
          setFetchStatus={setFetchStatus}
        />
      </div>
    </div>
  );
}
