import React, { useState, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { FaLink } from "react-icons/fa";
import { toast } from "react-hot-toast";
import Auth from "../../utils/auth";
import Sidebar from "../../components/sidebar/Sidebar";
import DashboardHeader from "../../components/dashboardHeader/DashboardHeader";
import Button from "../../components/button/Button";
import Table from "../../components/table/table";

const columnWidths = {
  ndc: { minWidth: "8rem", width: "10%" },
  fdaNdc: { minWidth: "8rem", width: "10%" },
  drugName: { minWidth: "12rem", width: "25%" },
  retailPrice: { minWidth: "6rem", width: "15%" },
  bestPrice: { minWidth: "6rem", width: "15%" },
  savings: { minWidth: "6rem", width: "15%" },
  pharmacy: { minWidth: "8rem", width: "15%" }
};

export default function RxLink() {
  const [ndcInput, setNdcInput] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("2061234567");
  const [zipCode, setZipCode] = useState("98501");
  const [pharmacyNPI, setPharmacyNPI] = useState("1750392254");
  const [results, setResults] = useState(null);
  const [rawResponse, setRawResponse] = useState(null);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const checkSavings = async (ndcList) => {
    try {
      const response = await fetch("/api/rxlink/checkSavings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`
        },
        body: JSON.stringify({ ndcList, phoneNumber, zipCode, pharmacyNPI })
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || "Failed to check savings");
        setResults(null);
        setRawResponse(data.rawResponse);
        return;
      }

      setResults(data.results);
      setRawResponse(data.rawResponse);
      setError(null);
    } catch (err) {
      setError(err.message);
      setResults(null);
      setRawResponse(null);
    }
  };

  // Load params from URL and check savings on mount
  useEffect(() => {
    const ndcsInputParameter = searchParams.get("ndcInput") || "59417010210,21695078460";
    const phoneNumberParameter = searchParams.get("phoneNumber") || "2061234567";
    const zipCodeParameter = searchParams.get("zipCode") || "98501";
    const pharmacyNPIParameter = searchParams.get("pharmacyNPI") || "1750392254";

    setNdcInput(ndcsInputParameter);
    setPhoneNumber(phoneNumberParameter);
    setZipCode(zipCodeParameter);
    setPharmacyNPI(pharmacyNPIParameter);

    const ndcList = ndcsInputParameter
      .split(/[\s,\n]+/)
      .map((ndc) => ndc.trim())
      .map((ndc) => ndc.replace(/-/g, ""))
      .filter((ndc) => ndc.length > 0);

    checkSavings(ndcList);
  }, [searchParams]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ndcList = ndcInput
      .split(/[\s,\n]+/)
      .map((ndc) => ndc.trim())
      .filter((ndc) => ndc.length > 0);

    if (ndcList.length === 0) {
      toast.error("Please enter at least one NDC code");
      return;
    }

    checkSavings(ndcList);
  };

  const generatePermalink = () => {
    if (!ndcInput.trim()) {
      toast.error("Please enter NDC codes first");
      return;
    }

    // Update URL with all parameters
    setSearchParams({ ndcInput, phoneNumber, zipCode, pharmacyNPI });

    // Copy to clipboard
    const url =
      `${window.location.origin}${window.location.pathname}` +
      `?ndcInput=${encodeURIComponent(ndcInput)}` +
      `&phoneNumber=${encodeURIComponent(phoneNumber)}` +
      `&zipCode=${encodeURIComponent(zipCode)}` +
      `&pharmacyNPI=${encodeURIComponent(pharmacyNPI)}`;
    navigator.clipboard.writeText(url);
    toast.success("Permalink copied to clipboard");
  };

  const asMoney = (value) => {
    const num = Number(value);
    if (Number.isNaN(num)) return value;
    return "$" + num.toFixed(2);
  };

  if (!Auth.loggedIn()) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen bg-grayColor">
      <Sidebar>
        <DashboardHeader withBackButton={false} title="RxLink NDC Lookup">
          <p className="m-0 text-grayColor">
            Check for prescription savings by entering NDC codes.
          </p>
        </DashboardHeader>

        <div className="p-4">
          <div className="mb-4 text-sm text-gray-600">
            Debug Tools:{" "}
            <a href="/errors" className="text-blue-500 hover:text-blue-600">
              Errors Page
            </a>
            {", "}
            <a href="/ndcProducts" className="text-blue-500 hover:text-blue-600">
              NDC Products
            </a>
            {", "}
            <a href="/ndcPackages" className="text-blue-500 hover:text-blue-600">
              NDC Packages
            </a>
            {", "}
            <a href="/rxlink" className="text-blue-500 hover:text-blue-600">
              RxLink Page
            </a>
          </div>
          <form onSubmit={handleSubmit} className="mb-4 mt-0">
            <div className="flex gap-4 mb-2">
              <div className="flex items-center">
                <label className="mr-2">Phone:</label>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="p-2 border rounded font-mono"
                  placeholder="Phone Number"
                />
              </div>
              <div className="flex items-center">
                <label className="mr-2">ZIP:</label>
                <input
                  type="text"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  className="p-2 border rounded font-mono"
                  placeholder="ZIP Code"
                />
              </div>
              <div className="flex items-center">
                <label className="mr-2">Pharmacy NPI:</label>
                <input
                  type="text"
                  value={pharmacyNPI}
                  onChange={(e) => setPharmacyNPI(e.target.value)}
                  className="p-2 border rounded font-mono"
                  placeholder="Pharmacy NPI"
                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="mr-2">NDCs:</label>
              <textarea
                value={ndcInput}
                onChange={(e) => setNdcInput(e.target.value)}
                className="w-full p-2 border rounded font-mono"
                rows={4}
                placeholder="Enter NDC codes (separated by commas, spaces, or new lines)"
              />
            </div>
            <div className="flex justify-between items-center">
              <Button type="submit">Check Savings</Button>
              <div className="flex gap-2">
                <a
                  href="https://docs.google.com/document/d/1vAuWib08A2Q_hp5qhqDtuJExttFI0grLat6FIjlnTYg"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-600 flex items-center">
                  <FaLink className="mr-1" /> Common Queries
                </a>
                <button
                  type="button"
                  onClick={generatePermalink}
                  className="text-blue-500 hover:text-blue-600 flex items-center">
                  <FaLink className="mr-1" /> Copy Permalink
                </button>
              </div>
            </div>
          </form>

          {error && <div className="text-red-500 mb-4">Error: {error}</div>}

          {results && results.length > 0 && (
            <div className="w-full overflow-hidden">
              <div className="text-gray-600 mb-2">
                Found savings information for {results.length} NDC code
                {results.length === 1 ? "" : "s"}
              </div>
              <div className="max-w-full overflow-hidden">
                <Table
                  className="table-fixed w-full"
                  headers={Object.keys(columnWidths).map((header) => (
                    <div
                      key={header}
                      className="select-none relative group max-w-full"
                      style={columnWidths[header]}>
                      {header.charAt(0).toUpperCase() + header.slice(1)}
                    </div>
                  ))}
                  data={results.map((result) => [
                    <div key="ndc">{result.ndc}</div>,
                    <div key="fdaNdc">{result.fdaNdc}</div>,
                    <div key="drugName">{result.drugName}</div>,
                    <div key="retailPrice">{asMoney(result.retailPrice)}</div>,
                    <div key="bestPrice">{asMoney(result.bestPrice)}</div>,
                    <div key="savings" className="text-successColor font-semibold">
                      {asMoney(result.savings)}
                    </div>,
                    <div key="pharmacy">{result.pharmacy}</div>
                  ])}
                />
              </div>
            </div>
          )}

          {results && results.length === 0 && (
            <div className="text-gray-500 text-center py-4">
              No savings found for the provided NDCs.
            </div>
          )}

          {rawResponse && (
            <div className="mt-8">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold m-0">Raw RxLink Response:</h3>
                <span className="text-gray-500 font-mono">
                  {rawResponse.timestamp || new Date().toISOString()}
                </span>
              </div>
              <pre className="bg-gray-100 p-4 rounded overflow-auto mt-2">
                {JSON.stringify(rawResponse, null, 2)}
              </pre>
            </div>
          )}

          {results && (
            <div className="mt-8">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold m-0">Check Savings API Results:</h3>
                <span className="text-gray-500 font-mono">
                  {rawResponse.timestamp || new Date().toISOString()}
                </span>
              </div>
              <pre className="bg-gray-100 p-4 rounded overflow-auto mt-2">
                {JSON.stringify(results, null, 2)}
              </pre>
            </div>
          )}
        </div>
      </Sidebar>
    </div>
  );
}
