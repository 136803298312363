import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useBreakpoints } from "react-breakpoints-hook";
import { AiFillDatabase } from "react-icons/ai";
// import { BsCurrencyDollar } from "react-icons/bs";
import { FaClinicMedical } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { MdLocalPharmacy } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { TbArrowsShuffle } from "react-icons/tb";
import { Link } from "react-router-dom";
import { scrollToSection } from "../../utils/scrollHelper";
import Button from "../button/Button";

const navigationItems = [
  { name: "Add Insurance", to: "/insuranceSelect", icon: FiPlus },
  { name: "Rx History", scroll: "rxHistory", icon: MdLocalPharmacy },
  { name: "Rx Interactions", scroll: "rxInteractions", icon: TbArrowsShuffle },
  { name: "Medicare Options", scroll: "medicareOptions", icon: AiFillDatabase },
  {
    name: "Health History",
    scroll: "healthHistory",
    icon: HiOutlineChartSquareBar
  },

  { name: "Clinical Trials", scroll: "clinicalTrials", icon: FaClinicMedical }
];

function joinClassNamesToString(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const { sm } = useBreakpoints({
    sm: { min: 0, max: 640 }
  });

  const handleSidebarNavigate = (section) => {
    const offset = sm ? 120 : 80;
    scrollToSection(section, offset);
    if (sm) {
      setSidebarOpen(false);
    }
  };

  const sidebarItemClasses = joinClassNamesToString(
    "text-whiteTextColor hover:bg-rgbaOpacityColor flex gap-x-3 rounded-md p-2 text-l leading-6 font-medium cursor-pointer outline-none focus:bg-rgbaOpacityColor",
    sidebarCollapsed ? "justify-center" : ""
  );

  // renders link if redirecting internally or clickable span if scrolling and added accessiblity
  const renderSidebarContent = () => (
    <div className="flex flex-1 flex-col">
      {navigationItems.map((item) => (
        <div key={item.name}>
          {item.to ? (
            <Link to={item.to} className={sidebarItemClasses}>
              <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
              {!sidebarCollapsed && item.name}
            </Link>
          ) : (
            <span
              role="link"
              tabIndex="0"
              onClick={() => handleSidebarNavigate(item.scroll)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  handleSidebarNavigate(item.scroll);
                }
              }}
              className={sidebarItemClasses}>
              <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
              {!sidebarCollapsed && item.name}
            </span>
          )}
        </div>
      ))}
    </div>
  );

  const toggleSidebarCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <div>
      {/* transition when opening and closing on mobile */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[999] mt-16 lg:hidden" onClose={setSidebarOpen}>
          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative mr-16 flex w-full max-w-56 flex-1 mt-16">
                <div className="absolute left-4 top-5 w-16 justify-center">
                  <div onClick={() => setSidebarOpen(false)}>
                    <span className="sr-only">Close sidebar</span>
                    <IoClose
                      className="text-whiteTextColor cursor-pointer text-mainTextColor h-6 w-6"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="flex grow flex-col overflow-y-auto bg-fourthAccentColor px-2 pb-2">
                  <div className="flex h-16 shrink-0 items-center" />
                  {renderSidebarContent()}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={joinClassNamesToString(
          "hidden mt-16 lg:fixed lg:inset-y-0 z-50 lg:flex lg:flex-col",
          sidebarCollapsed ? "lg:w-16" : "lg:w-60"
        )}>
        <div className="flex grow flex-col overflow-y-auto bg-fourthAccentColor px-2">
          <div
            className={joinClassNamesToString(
              sidebarCollapsed ? "justify-center" : "",
              "flex h-16 shrink-0 items-center"
            )}>
            <Button theme="noStyle" className="cursor-pointer" onClick={toggleSidebarCollapse}>
              {!sidebarCollapsed ? (
                <IoClose className="text-whiteTextColor h-6 w-6" />
              ) : (
                <RxHamburgerMenu className="text-whiteTextColor h-6 w-6" />
              )}
            </Button>
          </div>
          {renderSidebarContent()}
        </div>
      </div>

      {/* Static under navbar for mobile */}
      <div className="sticky top-16 z-[999] flex items-center bg-fourthAccentColor sm:px-6 lg:hidden p-1">
        <Button theme="noStyle" className="ml-[-1rem]" onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <RxHamburgerMenu
            className="text-whiteTextColor cursor-pointer hover:bg-rgbaOpacityColor rounded-md p-1 h-6 w-6"
            aria-hidden="true"
          />
        </Button>
        <span className="flex-1 font-semibold leading-6 mb-1 text-whiteTextColor">Menu</span>
      </div>

      <main
        className={joinClassNamesToString(!sidebarCollapsed ? "lg:pl-60" : "lg:pl-16", "pt-16")}>
        {children}
      </main>
    </div>
  );
}
