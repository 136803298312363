import React, { useState } from "react";
import Button from "../button/Button";
import Input from "../input/Input";

export default function ContactInformationForm({
  withLabel = true,
  direction = "col",
  submitContactInformation
}) {
  const [dayOfBirth, setDayOfBirth] = useState("01");
  const [monthOfBirth, setMonthOfBirth] = useState("01");
  const [yearOfBirth, setYearOfBirth] = useState("2001");
  const [formValues, setFormValues] = useState({
    patientId: "",
    gender: "",
    birthDate: yearOfBirth + "-" + monthOfBirth + "-" + dayOfBirth,
    streetAddress: "",
    city: "",
    state: "",
    postalCode: ""
  });

  const handleValueChange = (event) => {
    const { name, value } = event.target;

    if (name === "year") {
      setYearOfBirth(value);
      setFormValues((prevValues) => ({
        ...prevValues,
        birthDate: `${value}-${monthOfBirth}-${dayOfBirth}`
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value
      }));
    }
  };

  const states = [
    { value: "", label: "Select a state" },
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" }
  ];

  const months = [
    { values: "01", label: "January" },
    { values: "02", label: "February" },
    { values: "03", label: "March" },
    { values: "04", label: "April" },
    { values: "05", label: "May" },
    { values: "06", label: "June" },
    { values: "07", label: "July" },
    { values: "08", label: "August" },
    { values: "09", label: "September" },
    { values: "10", label: "October" },
    { values: "11", label: "November" },
    { values: "12", label: "December" }
  ];
  const days = [
    { values: "01", label: "01" },
    { values: "02", label: "02" },
    { values: "03", label: "03" },
    { values: "04", label: "04" },
    { values: "05", label: "05" },
    { values: "06", label: "06" },
    { values: "07", label: "07" },
    { values: "08", label: "08" },
    { values: "09", label: "09" },
    { values: "10", label: "10" },
    { values: "11", label: "11" },
    { values: "12", label: "12" },
    { values: "13", label: "13" },
    { values: "14", label: "14" },
    { values: "15", label: "15" },
    { values: "16", label: "16" },
    { values: "17", label: "17" },
    { values: "18", label: "18" },
    { values: "19", label: "19" },
    { values: "20", label: "20" },
    { values: "21", label: "21" },
    { values: "22", label: "22" },
    { values: "23", label: "23" },
    { values: "24", label: "24" },
    { values: "25", label: "25" },
    { values: "26", label: "26" },
    { values: "27", label: "27" },
    { values: "28", label: "28" },
    { values: "29", label: "29" },
    { values: "30", label: "30" },
    { values: "31", label: "31" }
  ];

  const genders = [
    { value: "", label: "" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" }
  ];

  return (
    <div className="px-8">
      {withLabel && (
        <p className="mt-1 mb-2">
          Submit your contact information to begin getting recommendations
        </p>
      )}
      <form onSubmit={(event) => submitContactInformation(event, formValues)}>
        <h5 className="text-left mt-4 -mb-1">Date of Birth</h5>
        <div>
          <label className=" text-lg block text-left mt-4 -mb-1">Day:</label>
          <select
            name="day"
            value={dayOfBirth}
            onChange={(event) => {
              const newValue = event.target.value;
              setDayOfBirth(newValue);
              setFormValues((prevValues) => ({
                ...prevValues,
                birthDate: `${yearOfBirth}-${monthOfBirth}-${newValue}`
              }));
            }}
            className="text-base p-4 border rounded my-2 w-full"
            required>
            {days.map((day) => (
              <option key={day.values} value={day.values}>
                {day.label}
              </option>
            ))}
          </select>
          <label className=" text-lg block text-left mt-4 -mb-1">Month:</label>
          <select
            name="month"
            value={monthOfBirth}
            onChange={(event) => {
              const newValue = event.target.value;
              setMonthOfBirth(newValue);
              setFormValues((prevValues) => ({
                ...prevValues,
                birthDate: `${yearOfBirth}-${newValue}-${dayOfBirth}`
              }));
            }}
            className="text-base p-4 border rounded my-2 w-full"
            required>
            {months.map((month) => (
              <option key={month.values} value={month.values}>
                {month.label}
              </option>
            ))}
          </select>
          <Input
            name="year"
            value={yearOfBirth}
            onChange={handleValueChange}
            setValue={(newValue) =>
              setYearOfBirth((prevValues) => ({
                ...prevValues,
                birthDate: newValue + monthOfBirth + dayOfBirth
              }))
            }
            label="Year"
            placeholder="Enter the year you were born"
            type="number"
            required
            inputClasses="text-base"
            labelClasses="mt-2 -mb-4"
          />
        </div>
        <div>
          <label className=" text-lg block text-left mt-4 -mb-1">Sex:</label>
          <select
            name="gender"
            value={formValues.gender}
            onChange={handleValueChange}
            className="text-base p-4 border rounded my-2 w-full"
            required>
            {genders.map((gender) => (
              <option key={gender.value} value={gender.value}>
                {gender.label}
              </option>
            ))}
          </select>
        </div>
        <h5 className="text-left mt-4 -mb-1">Address</h5>
        <div>
          <Input
            name="streetAddress"
            value={formValues.streetAddress}
            onChange={handleValueChange}
            setValue={(newValue) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                streetAddress: newValue
              }))
            }
            label="Street Address:"
            placeholder="Enter your street address"
            type="text"
            required
            inputClasses="text-base"
            labelClasses="mt-2 -mb-4"
          />
          <Input
            name="city"
            value={formValues.city}
            onChange={handleValueChange}
            setValue={(newValue) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                city: newValue
              }))
            }
            label="City:"
            placeholder="Enter your city"
            type="text"
            required
            inputClasses="text-base"
            labelClasses="mt-2 -mb-4"
          />
        </div>
        <div>
          <label className=" text-lg block text-left mt-4 -mb-1">State:</label>
          <select
            name="state"
            value={formValues.state}
            onChange={handleValueChange}
            className="text-base p-4 border rounded my-2 w-full"
            required>
            {states.map((state) => (
              <option key={state.value} value={state.value}>
                {state.label}
              </option>
            ))}
          </select>
          <Input
            name="postalCode"
            value={formValues.postalCode}
            onChange={handleValueChange}
            setValue={(newValue) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                zipCode: newValue
              }))
            }
            label="Zip Code:"
            placeholder="Enter your Zip Code"
            type="text"
            required
            inputClasses="text-base"
            labelClasses="mt-2 -mb-4"
          />
        </div>
        <div>
          <Button
            className={`${direction === "row" ? "mt-10 ml-1" : "mt-3"}`}
            fullWidth={direction !== "row"}
            type="submit"
            theme="success">
            Get Recommendations
          </Button>
        </div>
      </form>
    </div>
  );
}
