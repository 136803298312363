/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useBreakpoints } from "react-breakpoints-hook";
import { BiSearchAlt } from "react-icons/bi";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import patientFetches from "../../useFetch/userFetch/patientFetches";
import Auth from "../../utils/auth";
import {
  formatAllCapsSentences,
  makeFirstLetterCapital,
  renderingProviderCase
} from "../../utils/dataFormatHelpers";
import formatDateUtil from "../../utils/formatDate";
import formatMoney from "../../utils/formatMoney";
import { scrollToSection } from "../../utils/scrollHelper";
import Button from "../button/Button";
import DashboardCard from "../cards/healthHistoryDashboardCard";
import Input from "../input/Input";
import LoadingSkeleton from "../loadingSkeleton/loadingSkeleton";
import Table from "../table/table";
import Tooltip from "../tooltips/tooltipLogic";
import tooltipObject from "../tooltips/tooltipObject";
import DashboardExhibitContainer from "./dashboardExhibitContainer";
import EmptyState from "../dashboardEmptyState/emptyState";

const isMatch = (service, searchInput) => {
  if (
    (service.date && service.date.toLowerCase().includes(searchInput.toLowerCase())) ||
    (service.status && service.status.toLowerCase().includes(searchInput.toLowerCase())) ||
    (service.payment_amount &&
      service.payment_amount.toLowerCase().includes(searchInput.toLowerCase())) ||
    (service.type && service.type.toLowerCase().includes(searchInput.toLowerCase())) ||
    (service.outcome && service.outcome.toLowerCase().includes(searchInput.toLowerCase())) ||
    (service.rendering_provider &&
      service.rendering_provider.toLowerCase().includes(searchInput.toLowerCase()))
  )
    return true;

  for (let i = 0; i < service.diagnoses.length; i++) {
    if (
      (service.diagnoses[i].diagnosis_code &&
        service.diagnoses[i].diagnosis_code.toLowerCase().includes(searchInput.toLowerCase())) ||
      (service.diagnoses[i].diagnosis_display &&
        service.diagnoses[i].diagnosis_display.toLowerCase().includes(searchInput.toLowerCase()))
    )
      return true;
  }

  for (let i = 0; i < service.procedures.length; i++) {
    if (
      (service.procedures[i].procedure_code &&
        service.procedures[i].procedure_code.toLowerCase().includes(searchInput.toLowerCase())) ||
      (service.procedures[i].procedure_date &&
        service.procedures[i].procedure_date.toLowerCase().includes(searchInput.toLowerCase())) ||
      (service.procedures[i].procedure_display &&
        service.procedures[i].procedure_display.toLowerCase().includes(searchInput.toLowerCase()))
    )
      return true;
  }

  return false;
};

export default function HealthHistoryTable({ setFetchStatus, dashboardView }) {
  const [loadCount, setLoadCount] = useState(5);
  const [searchInput, setSearchInput] = useState("");
  const [dashboardSearchInput, setDashboardSearchInput] = useState("");
  const [patientEOBData, setPatientEOBData] = useState([]);
  const [activeTooltip, setActiveTooltip] = useState(false);
  const [visitDetailsTooltip, setVisitDetailsTooltip] = useState(false);
  const [renderingProviderTooltip, setRenderingProviderTooltip] = useState(false);
  const [diagnosisTooltip, setDiagnosisTooltip] = useState(false);
  const [procedureTooltip, setProcedureTooltip] = useState(false);
  const [costTooltip, setCostTooltip] = useState(false);
  const [renderedArray, setRenderedArray] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [status, setStatus] = useState("loading");
  const { email } = Auth.getUser().data;

  const navigate = useNavigate();

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleDashboardSearch = (e) => {
    setDashboardSearchInput(e.target.value);
  };

  const navigateToFullViewWithSearch = (e) => {
    e.preventDefault();
    const searchValue = dashboardSearchInput;
    const navigateURL = `/healthHistory?search=${searchValue}`;
    navigate(navigateURL);
  };

  const toggleRow = (id) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const loadMore = () => {
    Mixpanel("Health History Load More");
    ReactGATracking("Button Click", "Health History Load More");
    ReactPixelInitialize("Health History Load More", { value: email });
    // Increase the count by 5 on each click
    setLoadCount((prevCount) => prevCount + 5);
  };

  const { sm } = useBreakpoints({
    sm: { min: 0, max: 640 }
  });

  useEffect(() => {
    (async function healthHistoryFetch() {
      if (Auth.loggedIn()) {
        // get the user
        const userToken = Auth.getToken();
        try {
          const patientEOBDataDB = await patientFetches.getPatientEOB(userToken);
          // sort EOB entries by most recent
          const sortedPatientEOBData = patientEOBDataDB.sort((firstItem, lastItem) => {
            const firstDate = new Date(firstItem.date);
            const lastDate = new Date(lastItem.date);
            return lastDate - firstDate;
          });

          if (patientEOBDataDB.length === 0) {
            setStatus("missing");
          }

          if (patientEOBDataDB.length > 0) {
            setStatus("complete");
          }

          setPatientEOBData(sortedPatientEOBData);
          // Only render a maximum of the current load count, initially set to 5
          setRenderedArray(patientEOBDataDB.slice(0, loadCount));
          setFetchStatus((prevState) => ({
            ...prevState,
            healthHistory: "success"
          }));

          const searchParam = new URLSearchParams(window.location.search).get("search");
          if (searchParam) {
            setSearchInput(searchParam);
          }
          const openServiceIDParam = new URLSearchParams(window.location.search).get("serviceID");
          if (openServiceIDParam) {
            toggleRow(openServiceIDParam);
            // wait half a second for content to load before scrolling
            await new Promise((resolve) => {
              setTimeout(resolve, 500);
            });
            scrollToSection(openServiceIDParam + " openOnLoad", -500);
          }
        } catch (err) {
          console.error(err);
          setFetchStatus((prevState) => ({
            ...prevState,
            healthHistory: "failed"
          }));
          setStatus("failed");
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (searchInput) {
      setRenderedArray(filteredServices.slice(0, loadCount));
      return;
    }
    setRenderedArray(patientEOBData.slice(0, loadCount));
  }, [loadCount]);

  // filter the health history based on the search input
  useEffect(() => {
    const filtered = patientEOBData.filter((service) => isMatch(service, searchInput));
    // Only render the first 5 results pertaining to the search
    setRenderedArray(filtered.slice(0, loadCount));
    setFilteredServices(filtered);
    // If search input is empty set the EOB back to the way it was
    if (!searchInput.length || searchInput.length === 0) {
      setFilteredServices([]);
      setRenderedArray(filtered.slice(0, loadCount));
    }
  }, [searchInput]);

  const renderTableData = () => {
    if (status === "loading") {
      const loadingSkeletonArray = new Array(3);
      loadingSkeletonArray.fill([
        <>
          <LoadingSkeleton skeletonRenderCount={2} className="mt-6" key="1" />
          <br />
          <LoadingSkeleton
            height={2}
            backgroundColor="var(--main-text-color)"
            className=""
            key="2"
          />
        </>,
        <LoadingSkeleton className="mt-6" key="3" />,
        <LoadingSkeleton className="mt-6" key="4" />,
        <LoadingSkeleton className="mt-6" key="5" />,
        <LoadingSkeleton className="mt-6" key="6" />
      ]);
      return loadingSkeletonArray;
    }
    if (status === "complete") {
      return renderedArray.map((service) => [
        <div id={service.id + " openOnLoad"}>
          <strong>{service.type ? makeFirstLetterCapital(service.type) : "-"}</strong>
          <br />
          <>{service.date ? formatDateUtil.formatDate(service.date) : "-"}</>
          <br />
          <br />
          {((service.procedures && service.procedures.length > 0) ||
            (service.diagnoses && service.diagnoses.length > 0)) && (
            <Button
              onClick={() => {
                toggleRow(service.id);
              }}>
              {expandedRows[service.id] ? "- Close" : "+ Full view"}
            </Button>
          )}
        </div>,
        <>{service.rendering_provider ? renderingProviderCase(service.rendering_provider) : "-"}</>,
        <>
          {service.diagnoses[0]?.diagnosis_display
            ? formatAllCapsSentences(service.diagnoses[0].diagnosis_display)
            : "-"}
          <br />
        </>,
        <>
          {service.procedures[0]?.procedure_display
            ? formatAllCapsSentences(service.procedures[0].procedure_display)
            : "-"}
        </>,
        <>
          {service.payment_amount
            ? `${formatMoney.formatMoneyWithCents(service.payment_amount)}`
            : "-"}
        </>
      ]);
    }
    return null;
  };

  const renderSubtitle = () => {
    if (!dashboardView) {
      return (
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
          <div>Here you can view your health history.</div>
          <div className="lg:w-2/5">
            <Input
              setValue={setSearchInput}
              label=""
              inlineStartIcon={BiSearchAlt}
              type="text"
              placeholder="Search Service Name"
              onChange={handleSearch}
              value={searchInput}
            />
          </div>
        </div>
      );
    }
    if (status === "complete") {
      return (
        <div className="flex flex-col justify-between items-start">
          <div>A list of your health history and the primary diagnosis and/or procedure.</div>
          <form
            className="lg:w-2/5 m-0 flex flex-col lg:flex-row lg:justify-start my-4"
            onSubmit={(e) => {
              navigateToFullViewWithSearch(e);
            }}>
            <Input
              setValue={setDashboardSearchInput}
              label=""
              inlineStartIcon={BiSearchAlt}
              type="text"
              placeholder="Search Health History"
              onChange={handleDashboardSearch}
              value={dashboardSearchInput}
            />
            <Button type="submit" className="mt-4 lg:mt-0 lg:ml-4 text-base">
              Search
            </Button>
          </form>
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      <DashboardExhibitContainer
        title={
          <div>
            Health History
            <FaRegCircleQuestion
              className="text-sm mb-2 ml-2"
              onMouseOver={() => setActiveTooltip(true)}
              onMouseLeave={() => setActiveTooltip(false)}
            />
            {activeTooltip && (
              <Tooltip
                className="tooltipContainer flex items-center"
                text={tooltipObject.healthHistoryTitle}
                size={sm ? "beneathInline" : "small"}
              />
            )}
          </div>
        }
        subtitle={renderSubtitle()}
        viewAll={dashboardView}
        viewAllCount={dashboardView ? patientEOBData.length : null}
        viewAllParam="/healthHistory">
        {!dashboardView ? (
          <>
            <Table
              tableDivClass="universalTableDivClass"
              tableClass={`${
                patientEOBData.length || status === "loading" ? "" : "noDataHeaders"
              } universalTable universalTableDark healthHistoryMobile`}
              headers={[
                <div>
                  Visit &nbsp;
                  <br />
                  Details
                  <FaRegCircleQuestion
                    className="text-sm ml-2"
                    onMouseOver={() => setVisitDetailsTooltip(true)}
                    onMouseLeave={() => setVisitDetailsTooltip(false)}
                  />
                  {visitDetailsTooltip && (
                    <Tooltip
                      className="tooltipContainer flex items-center"
                      text={tooltipObject.visitDetailsHeader}
                      size={sm ? "beneathInline" : "small"}
                    />
                  )}
                </div>,
                <div className="">
                  Rendering Provider
                  <FaRegCircleQuestion
                    className="text-sm ml-2"
                    onMouseOver={() => setRenderingProviderTooltip(true)}
                    onMouseLeave={() => setRenderingProviderTooltip(false)}
                  />
                  {renderingProviderTooltip && (
                    <Tooltip
                      className="tooltipContainer flex items-center"
                      text={tooltipObject.renderingProviderHeader}
                      size="beneathInline"
                    />
                  )}
                </div>,
                <div className="whitespace-nowrap">
                  Diagnosis
                  <FaRegCircleQuestion
                    className="text-sm ml-2"
                    onMouseOver={() => setDiagnosisTooltip(true)}
                    onMouseLeave={() => setDiagnosisTooltip(false)}
                  />
                  {diagnosisTooltip && (
                    <Tooltip
                      className="tooltipContainer flex items-center"
                      text={tooltipObject.diagnosisHeader}
                      size="beneathInline"
                    />
                  )}
                </div>,
                <div className="whitespace-nowrap">
                  Procedure
                  <FaRegCircleQuestion
                    className="text-sm ml-2"
                    onMouseOver={() => setProcedureTooltip(true)}
                    onMouseLeave={() => setProcedureTooltip(false)}
                  />
                  {procedureTooltip && (
                    <Tooltip
                      className="tooltipContainer flex items-center"
                      text={tooltipObject.procedureHeader}
                      size="beneathInline"
                    />
                  )}
                </div>,
                <div className="whitespace-nowrap">
                  Patient <br /> Paid
                  <FaRegCircleQuestion
                    className="text-sm ml-2"
                    onMouseOver={() => setCostTooltip(true)}
                    onMouseLeave={() => setCostTooltip(false)}
                  />
                  {costTooltip && (
                    <Tooltip
                      className="tooltipContainer flex items-center"
                      text={tooltipObject.costHeader}
                      size={sm ? "beneathInline" : "large"}
                    />
                  )}
                </div>
              ]}
              data={renderTableData()}
              nestedRows={renderedArray.map((service) =>
                expandedRows[service.id] ? (
                  <>
                    {service.diagnoses && service.diagnoses.length > 0 && (
                      <>
                        <Table
                          key={`nested diagnoses ${service.id}`}
                          tableClass="nestedTable diagnosisSubtableMobile"
                          headers={["Diagnosis Code", "Diagnosis Display"]}
                          data={service.diagnoses.map((diagnosis) => [
                            <>{diagnosis.diagnosis_code ? diagnosis.diagnosis_code : "-"}</>,
                            <>
                              {diagnosis.diagnosis_display
                                ? formatAllCapsSentences(diagnosis.diagnosis_display)
                                : "-"}
                            </>
                          ])}
                        />
                        <br />
                      </>
                    )}
                    {service.procedures && service.procedures.length > 0 && (
                      <Table
                        key={`nested procedures ${service.id}`}
                        tableClass="nestedTable proceduresSubtableMobile"
                        headers={["Procedure Date", "Procedure Code", "Procedure Display"]}
                        data={service.procedures.map((procedure) => [
                          <>
                            {procedure.procedure_date
                              ? formatDateUtil.formatDate(procedure.procedure_date)
                              : "-"}
                          </>,
                          <>{procedure.procedure_code ? procedure.procedure_code : "-"}</>,
                          <>
                            {procedure.procedure_display
                              ? formatAllCapsSentences(procedure.procedure_display)
                              : "-"}
                          </>
                        ])}
                      />
                    )}
                  </>
                ) : null
              )}
            />
            {(patientEOBData.length > loadCount && !filteredServices.length && !searchInput) ||
            filteredServices.length > loadCount ? (
              <Button
                className="rounded-b-md rounded-t-none text-base"
                fullWidth
                onClick={loadMore}>
                Load more...
              </Button>
            ) : null}
          </>
        ) : (
          <DashboardCard status={status} data={patientEOBData} />
        )}
        {status === "missing" && (
          <EmptyState title="Health History" onClick={() => navigate("/insuranceSelect")} />
        )}
        {status === "failed" && (
          <p className="m-0 p-2">
            Something went wrong with fetching your Health History. Please try again later.
          </p>
        )}
      </DashboardExhibitContainer>
    </>
  );
}
