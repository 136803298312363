import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AnthemLogo from "../../assets/Anthem-Logo.png";
import CenteneLogo from "../../assets/Centene_Logo.png";
import HumanaLogo from "../../assets/Humana_logo.png";
import HealthnetLogo from "../../assets/healthnetLogo.png";
import UnitedLogo from "../../assets/United-Healthcare-Logo.png";
import AARPLogo from "../../assets/aarpLogo.png";
// import AscensionLogo from "../../assets/ascensionLogo.png";
import AetnaLogo from "../../assets/aetnaLogo.png";
import CignaLogo from "../../assets/cigna_logo.png";
import KaiserLogo from "../../assets/kaiser.png";
import MedicareGovLogo from "../../assets/medicareDotGov.svg";
// import PeoplesHealthLogo from "../../assets/peoplesHealthLogo.png";
import RockyMountainLogo from "../../assets/rockyMountainLogo.png";
import SierraHealthAndLifeLogo from "../../assets/sierraLogo.png";
import SilverscriptLogo from "../../assets/silverscriptLogo.png";
import TrilliumLogo from "../../assets/trillium-chp-logo-main.png";
import WellcareLogo from "../../assets/wellcareLogo.png";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Button from "../../components/button/Button";
import Card from "../../components/cards/card";
import Carousel, { CarouselItem } from "../../components/carousels/Carousel";
import Input from "../../components/input/Input";
import "../../components/loginModal/loginModal";
import Auth from "../../utils/auth";
import InsurerCardButton from "./InsurerCardButton";
import RequestInsurerAdd from "./RequestInsurerAdd";
import "./insurance.css";
import InsuranceRedirectWarningModal from "./insuranceRedirectWarningModal";

function StepOne() {
  return (
    <Card className=" row-span-3 bg-white shadow-lg mx-8 mb-2">
      <h3 className="-mt-4">Step 1 🔑</h3>
      <p className="text-[1.2rem] -mt-2 mb-12 p-4 ">Pick your insurance company</p>
    </Card>
  );
}

function StepTwo() {
  return (
    <Card className="row-span-3 bg-white shadow-lg mx-8 mb-2">
      <h3 className="-mt-4">Step 2 🔗</h3>{" "}
      <p className="text-[1.2rem] -mt-4 mb-12 p-4  ">
        Login or sign up. Have your insurance card ready.
      </p>
    </Card>
  );
}

function StepThree() {
  return (
    <Card className="row-span-3 bg-white shadow-lg p-8 mx-8 mb-2">
      <h3 className="-mt-4">Step 3 📊</h3>{" "}
      <p className="text-[1.2rem] -mt-4 mb-12 p-4 "> Wait 60 seconds for your data to load.</p>
    </Card>
  );
}

function PageHeader() {
  return (
    <div className="mt-6">
      <div className="mx-8 -mb-44 max-lg:hidden mx-auto grid grid-rows-3 grid-flow-col gap-6">
        <StepOne />
        <StepTwo />
        <StepThree />
      </div>
      <div className="mx-8 lg:invisible mb-44">
        <Carousel>
          <CarouselItem>
            <StepOne />{" "}
          </CarouselItem>
          <CarouselItem>
            <StepTwo />{" "}
          </CarouselItem>
          <CarouselItem>
            <StepThree />{" "}
          </CarouselItem>
        </Carousel>
      </div>
    </div>
  );
}

export default function InsuranceSelect() {
  // create a navigate function from the useNavigate hook
  const navigate = useNavigate();
  //  ---- state for opening first modal
  const [dashboardModal, setDashboardModal] = useState(true);
  //  ---- state for insurerInfoWithToken
  const [insurerInfoWithToken, setInsurerInfoWithToken] = useState([]);
  //  ---- state for insurerInfoWithToken
  const [insurerInfoWithoutTok, setInsurerInfoWithoutTok] = useState([]);
  //  ---- state for user search input
  const [searchInput, setSearchInput] = useState("");
  //  ---- state for matching insurer names
  const [matchingInsurers, setMatchingInsurers] = useState([]);
  //  ---- state for showing the sandbox buttons
  const [showSandbox, setShowSandbox] = useState(false);
  //  ---- state for showing the add insurer form
  const [showInsurerAdd, setShowInsurerAdd] = useState(false);
  //  ---- state for loading
  const [loading, setLoading] = useState(false);
  //  ---- state for redirect warning modal
  const [openRedirect, setOpenRedirect] = useState(false);
  //  ---- state for clicked insurer
  const [clickedInsurer, setClickedInsurer] = useState("");
  //  ---- state for associated insurer
  const [associatedInsurer, setAssociatedInsurer] = useState("");
  //  ---- state for clicked insurer server name
  const [clickedInsurerServerName, setClickedInsurerServerName] = useState("");
  //  ---- state for clicked subset
  const [clickedSubset, setClickedSubset] = useState("");

  let JWTtoken;
  // check if user is logged in, and if not, redirect to home page, else get JWT token
  if (Auth.loggedIn()) {
    JWTtoken = Auth.getToken();
  } else {
    navigate("/");
  }

  const handleSandboxButtons = (e) => {
    e.preventDefault();
    setShowSandbox(!showSandbox);
  };

  const handleAddInsurer = (e) => {
    e.preventDefault();
    setShowInsurerAdd(!showInsurerAdd);
  };
  // Switches the modal to the next modal
  const switchModal = (e) => {
    e.preventDefault();
    if (dashboardModal) {
      setDashboardModal(false);
    } else {
      setDashboardModal(true);
    }
  };

  const navigateToLink = async (
    e,
    insurerName,
    subsetName,
    insurerParam,
    associatedInsurerParam,
    redirectWarned
  ) => {
    e.preventDefault();
    setClickedInsurer(insurerParam);
    setAssociatedInsurer(associatedInsurerParam);
    setClickedInsurerServerName(insurerName);
    setClickedSubset(subsetName);

    if (associatedInsurerParam && !redirectWarned) {
      setOpenRedirect(true);
      return;
    }

    let fetchUrl = `/api/insurerDataFetch/${insurerName}Fetch`;
    if (subsetName) {
      fetchUrl += `/${subsetName}`;
    }
    fetchUrl += "/oauth2/authorize";
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        authorization: "Bearer " + JWTtoken
      }
    });
    if (response.ok) {
      const data = await response.json();

      if (data.link) {
        window.location.assign(data.link);
      } else {
        toast.error("There was an error redirecting. Please try again later.");
      }
    } else {
      const error = await response.json();
      console.error(`Error retrieving redirect link: ${error}`);
      toast.error("There was an error redirecting. Please try again later.");
    }
  };
  // map function reference to render the insurer cards
  const mapNonSandboxInsurerCards = (insurer, index) => {
    if (!insurer.sandbox) {
      return (
        <InsurerCardButton
          key={`${insurer.name} - ${index}`}
          insurer={insurer}
          switchModal={switchModal}
          JWTtoken={JWTtoken}
          navigateToLink={navigateToLink}
        />
      );
    }
  };

  // map function reference to render the insurer cards
  const mapSandboxInsurerCards = (insurer, index) => {
    if (insurer.sandbox) {
      return (
        <InsurerCardButton
          key={`${insurer.name} - ${index}`}
          insurer={insurer}
          switchModal={switchModal}
          JWTtoken={JWTtoken}
          navigateToLink={navigateToLink}
        />
      );
    }
  };

  // map function reference to render the insurer cards
  const mapInsurerCards = (insurer, index) => (
    <InsurerCardButton
      key={`${insurer.name} - ${index}`}
      insurer={insurer}
      switchModal={switchModal}
      JWTtoken={JWTtoken}
      navigateToLink={navigateToLink}
    />
  );

  // this array will house information for each insurer
  const insurerInfo = [
    {
      name: "United Healthcare",
      Logo: UnitedLogo,
      alt: "United Healthcare Logo",
      insurer: "uhc",
      sandbox: false,
      altTags: ["aarp", "uhc"],
      subset: "uhc"
    },
    {
      name: "United Healthcare",
      Logo: UnitedLogo,
      alt: "United Healthcare Logo",
      insurer: "uhcSandbox",
      sandbox: true,
      altTags: ["aarp", "uhc"]
    },
    {
      name: "Medicare",
      Logo: MedicareGovLogo,
      alt: "Blue Button logo",
      insurer: "blueButton",
      sandbox: false,
      altTags: [
        "cms",
        "medicare",
        "medicare.gov",
        "Supplement",
        "MedSup",
        "Med-Sup",
        "Medigap",
        "Ffs",
        "Federal",
        "classic"
      ]
    },
    {
      name: "Medicare",
      Logo: MedicareGovLogo,
      alt: "Blue Button logo",
      insurer: "blueButtonSandbox",
      sandbox: true,
      altTags: [
        "cms",
        "medicare",
        "medicare.gov",
        "Supplement",
        "MedSup",
        "Med-Sup",
        "Medigap",
        "Ffs",
        "Federal",
        "classic"
      ]
    },
    {
      name: "Aetna",
      Logo: AetnaLogo,
      alt: "Aetna Logo",
      insurer: "aetna",
      sandbox: false
    },
    {
      name: "Aetna",
      Logo: AetnaLogo,
      alt: "Aetna Logo",
      insurer: "aetnaSandbox",
      sandbox: true
    },
    {
      name: "Humana",
      Logo: HumanaLogo,
      alt: "Humana Logo",
      insurer: "humana",
      sandbox: false
    },
    // {
    //   name: "Humana",
    //   Logo: HumanaLogo,
    //   alt: "Humana Logo",
    //   insurer: "humanaSandbox",
    //   sandbox: true
    // },
    {
      name: "Kaiser Permanente",
      Logo: KaiserLogo,
      alt: "Kaiser Permanente Logo",
      insurer: "kaiser",
      sandbox: false,
      altTags: ["kpp"]
    },
    {
      name: "Kaiser Permanente",
      Logo: KaiserLogo,
      alt: "Kaiser Permanente Logo",
      insurer: "kaiserSandbox",
      sandbox: true,
      altTags: ["kpp"]
    },
    {
      name: "AARP",
      Logo: AARPLogo,
      alt: "AARP Logo",
      insurer: "uhcSandbox",
      sandbox: true,
      altTags: ["aarp", "uhc"],
      associatedInsurer: "United Healthcare"
    },
    {
      name: "AARP",
      Logo: AARPLogo,
      alt: "AARP Logo",
      insurer: "uhc",
      sandbox: false,
      altTags: ["aarp", "uhc"],
      associatedInsurer: "United Healthcare",
      subset: "uhc"
    },
    {
      name: "Anthem",
      Logo: AnthemLogo,
      alt: "Anthem Logo",
      insurer: "anthem",
      sandbox: false
    },
    {
      name: "Anthem",
      Logo: AnthemLogo,
      alt: "Anthem Logo",
      insurer: "anthemSandbox",
      sandbox: true
    },
    {
      name: "Centene",
      Logo: CenteneLogo,
      alt: "Centene Logo",
      insurer: "centeneSandbox",
      sandbox: true
    },
    {
      name: "Centene",
      Logo: CenteneLogo,
      alt: "Centene Logo",
      altTags: ["centene", "healthnet", "wellcare", "Trillium Community Health Plan"],
      insurer: "centene",
      sandbox: false
    },
    {
      name: "healthnet",
      Logo: HealthnetLogo,
      alt: "Healthnet Logo",
      insurer: "centene",
      sandbox: false
    },
    {
      name: "Trillium Community Health Plan",
      Logo: TrilliumLogo,
      alt: "Trillium Community Health Plan Logo",
      insurer: "centene",
      sandbox: false
    },
    // {
    //   name: "Ascension",
    //   Logo: AscensionLogo,
    //   alt: "Ascension Logo",
    //   insurer: "ascension",
    //   sandbox: false
    // },
    // {
    //   name: "Ascension",
    //   Logo: AscensionLogo,
    //   alt: "Ascension Logo",
    //   insurer: "ascensionSandbox",
    //   sandbox: true
    // },
    {
      name: "Cigna",
      Logo: CignaLogo,
      alt: "Cigna Logo",
      insurer: "cigna",
      sandbox: false
    },
    // {
    //   name: "Cigna",
    //   Logo: CignaLogo,
    //   alt: "Cigna Logo",
    //   insurer: "cignaSandbox",
    //   sandbox: true
    // },
    // {
    //   name: "Peoples Health",
    //   Logo: PeoplesHealthLogo,
    //   alt: "Peoples Health Logo",
    //   insurer: "uhc",
    //   sandbox: false,
    //   altTags: ["uhc", "united", "healthx"],
    //   subset: "peoplesHealth"
    // },
    {
      name: "Rocky Mountain",
      Logo: RockyMountainLogo,
      alt: "Rocky Mountain Logo",
      insurer: "uhc",
      sandbox: false,
      altTags: ["uhc", "united", "rmhp"],
      subset: "rockyMountain"
    },
    {
      name: "Sierra Health And Life",
      Logo: SierraHealthAndLifeLogo,
      alt: "Sierra Health And Life Logo",
      insurer: "uhc",
      sandbox: false,
      altTags: ["uhc", "united", "sierra"],
      subset: "sierraHealthAndLife"
    },
    {
      name: "SilverScript",
      Logo: SilverscriptLogo,
      alt: "SilverScript Logo",
      insurer: "aetnaSandbox",
      sandbox: true,
      associatedInsurer: "Aetna"
    },
    {
      name: "SilverScript",
      Logo: SilverscriptLogo,
      alt: "SilverScript Logo",
      insurer: "aetna",
      sandbox: false,
      associatedInsurer: "Aetna"
    },
    {
      name: "WellCare",
      Logo: WellcareLogo,
      alt: "WellCare Logo",
      insurer: "centeneSandbox",
      sandbox: true,
      associatedInsurer: "Centene"
    },
    {
      name: "WellCare",
      Logo: WellcareLogo,
      alt: "WellCare Logo",
      insurer: "centene",
      sandbox: false,
      associatedInsurer: "Centene"
    }
  ];

  useEffect(() => {
    (async () => {
      // initiate loading
      setLoading(true);

      const { email } = Auth.getUser().data;
      let errorMessage = "There was an error loading insurers. Please try again later.";
      Mixpanel("Insurance Select View");
      ReactGATracking("Page View", "Insurance Select");
      ReactPixelInitialize("Insurance Select Page", { value: email });

      try {
        // get the insurer names from our insurer object into an array of string values
        const insurerNames = insurerInfo.map((insurer) => insurer.insurer);

        // send request to back end with insurer names
        const tokenValidResponse = await fetch("/api/insurerDataFetch/checkToken", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + JWTtoken
          },
          body: JSON.stringify({
            insurers: insurerNames
          })
        });

        if (tokenValidResponse.ok) {
          const tokenValidData = await tokenValidResponse.json();
          // Update insurerInfo array with token validity
          const updatedInsurerInfo = insurerInfo.map((insurer) => {
            const validToken = tokenValidData[insurer.insurer];
            return {
              ...insurer,
              tokenValid: validToken || false
            };
          });

          setInsurerInfoWithToken(updatedInsurerInfo.filter((insurer) => insurer.tokenValid));
          setInsurerInfoWithoutTok(updatedInsurerInfo.filter((insurer) => !insurer.tokenValid));
        } else {
          toast.error(errorMessage);
        }
        // set error message to empty string indicating there is no longer an issue with insurer loading
        errorMessage = "";
      } catch (error) {
        if (errorMessage) {
          toast.error(errorMessage);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // this useEffect will filter the insurerInfo array based on the user's search input
  useEffect(() => {
    // this function will filter the insurerInfo array based on the user's search input
    const filterInsurerInfo = () => {
      // if the user's search input is empty, return an empty array
      if (searchInput === "") {
        return [];
      }

      // filter the insurerInfo array based on the user's search input
      // if an insurer has alt tags, we use the .some method to see if any strings within our alt tags array are partially or fully present in the search input
      const filteredInsurerInfo = insurerInfo.filter(
        (insurer) =>
          !insurer.sandbox &&
          (insurer.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            insurer.insurer.toLowerCase().includes(searchInput.toLowerCase()) ||
            (insurer.altTags &&
              insurer.altTags.some((altTag) =>
                altTag.toLowerCase().includes(searchInput.toLowerCase())
              )))
      );

      // return the filtered insurerInfo array
      return filteredInsurerInfo;
    };

    // set the state for the filtered insurerInfo array
    setMatchingInsurers(filterInsurerInfo());
  }, [searchInput]);

  // this function will render the insurer cards if found in the matchingInsurers array, or render a form to add a new insurer if not found
  const renderSearchedInsurers = () => {
    // if the search input is empty, return null
    if (!searchInput) {
      return null;
    }
    // if the matchingInsurers array isn't empty (meaning the user's search input matches an insurer), render the insurer cards
    if (matchingInsurers.length > 0) {
      return (
        <div>
          <div className="insuranceCompanies insuranceCompaniesAdded">
            {searchInput && matchingInsurers.map(mapNonSandboxInsurerCards)}
          </div>
        </div>
      );
    }
    // if the matchingInsurers array is empty (meaning the user's search input doesn't match an insurer), render the form to add a new insurer
    return <RequestInsurerAdd />;
  };

  return (
    <div>
      {" "}
      {/* Create a loading overlay if loading */}
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 text-mainTextColor z-[9999]">
          <div className="spinner" />
        </div>
      )}
      <div className="flex justify-evenly flex-col">
        <div className="text-center max-w-7xl mx-auto">
          {/* <h1>All of Your Healthcare Data at Your Finger-Tips</h1> */}
          <h2 className=" mt-24 mx-4 mb-2">Gather all of your health data into one spot!</h2>
          {showInsurerAdd ? (
            <div className="-mt-1 px-2">
              <header>
                <p>
                  Please fill out the form below, or{" "}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={handleAddInsurer} className="hyperlink">
                    search for your insurance company.
                  </a>
                </p>
              </header>
              <section className="insuranceModalBody">
                <RequestInsurerAdd />
              </section>
            </div>
          ) : (
            <body className="flex justify-evenly flex-col ">
              <PageHeader />
              <div className="max-w-4xl mx-auto -mt-40 px-2 items-center inline-grid grid-cols-2 -gap-2">
                <p className="text-xl mr-24 mb-4">Search:</p>
                <Input
                  label=""
                  value={searchInput}
                  setValue={setSearchInput}
                  type="text"
                  placeholder="e.g. Humana"
                  labelClasses="text-xl"
                  inputClasses="text-xl -ml-24"
                />
              </div>

              {/* This section will render the main contents of the page. */}
              <section className="insuranceModalBody">
                {/* Map out the unadded insurers */}
                {insurerInfoWithoutTok.length > 0 && !searchInput && (
                  <div>
                    <h3>Our Current Insurance Companies:</h3>
                    {showSandbox ? (
                      <div className="insuranceCompanies">
                        {insurerInfoWithoutTok.map(mapSandboxInsurerCards)}
                      </div>
                    ) : (
                      <div className="insuranceCompanies">
                        {insurerInfoWithoutTok.map(mapNonSandboxInsurerCards)}
                      </div>
                    )}
                  </div>
                )}

                {/* Map out the added insurers or render nothing if there is no insurer */}
                {insurerInfoWithToken.length > 0 && !searchInput && (
                  <div>
                    <h2>Active Insurance Companies</h2>
                    <div className="insuranceCompanies insuranceCompaniesAdded">
                      {insurerInfoWithToken.map(mapInsurerCards)}
                    </div>
                  </div>
                )}

                {/* Map out all searched insurers */}
                {renderSearchedInsurers()}
                <div>
                  <h3 className="mb-2">Can&apos;t find your health insurance company?</h3>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={handleAddInsurer} className="hyperlink text-2xl">
                    request a new insurance company.
                  </a>
                </div>
              </section>
            </body>
          )}
          {!showInsurerAdd && (
            <Button onClick={handleSandboxButtons} className="m-10 ali">
              {" "}
              Sandbox Insurers
            </Button>
          )}
        </div>
      </div>
      <InsuranceRedirectWarningModal
        open={openRedirect}
        setOpen={setOpenRedirect}
        clickedInsurer={clickedInsurer}
        setClickedInsurer={setClickedInsurer}
        associatedInsurer={associatedInsurer}
        setAssociatedInsurer={setAssociatedInsurer}
        clickedInsurerServerName={clickedInsurerServerName}
        setClickedInsurerServerName={setClickedInsurerServerName}
        navigateToLink={navigateToLink}
        subset={clickedSubset}
      />
    </div>
  );
}
