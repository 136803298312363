import Auth from "../../utils/auth";

export default {
  executeQuery: async (query) => {
    const token = Auth.getToken();

    try {
      const response = await fetch("/api/errors/query", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ query })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to execute query");
      }

      return { myResults: data.results, myError: null };
    } catch (err) {
      return { myResults: null, myError: err.message };
    }
  }
};
