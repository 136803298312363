/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import decode from "jwt-decode";
import toast from "react-hot-toast";

//= === MODULAR VARIABLES
const passwordVariables = {
  numbers: true,
  letters: true,
  specialCharacter: true,
  minPassLength: 9
};

const tokenTimeoutMinutes = 4;

// sends a post request to the login route to try to authenticate the user
const loginUser = async (userInput, setErrorText) => {
  try {
    const res = await fetch(`/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(userInput)
    });

    const data = await res.json();
    if (data.message === "Login passed") {
      // temporary token
      const { token } = data;

      setErrorText("");

      localStorage.setItem("userToken", token);

      // redirect to correct page
      if (data.hasInsurance) {
        window.location.assign(`/app/dashboard`);
      } else {
        window.location.assign(`/insuranceSelect`);
      }
    } else {
      setErrorText(data.message);
    }
  } catch (e) {
    console.error(e.message);
    toast.error("There was an error logging in. Please try again later.");
  }
};

// sends a post request to the signup route to try and create a new user
const signupUser = async (userInput, setErrorText) => {
  try {
    const res = await fetch(`/api/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(userInput)
    });

    const data = await res.json();

    if (data.message === "Signup passed") {
      const { token } = data;

      if (token !== "undefined" || token !== "") {
        // Why isn't there like a `login(token)` token you can re-use
        // between the login component and signup component?

        // token in local storage
        localStorage.setItem("userToken", token);
        // reset error
        setErrorText("");

        // redirect...and go!
        window.location.assign(`/insuranceSelect`);

        return token;
      }
    } else {
      // We probably have an error message from the API, so let's display it.
      return setErrorText(data.message);
    }
  } catch (e) {
    console.error(e.message);
    toast.error("There was an error signing up. Please try again later.");
  }
};

// function to request a password recovery update
export const requestPassRecovUpdate = async (password, email, temptoken, setErrorText) => {
  try {
    const response = await fetch("/api/updateUserPassword", {
      method: "PUT",
      body: JSON.stringify({
        password,
        email
      }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${temptoken}`
      }
    });
    const data = await response.json();
    if (response.ok && data.message === "Password Updated") {
      return "success";
    }
    setErrorText("Could not update password. Please try again later.");
    return "failed";
  } catch (err) {
    console.error(err);
    setErrorText("Could not update password. Please try again later.");
    return "failed";
  }
};

class AuthService {
  // eslint-disable-next-line class-methods-use-this
  validation = async (userInput, validationType, states) => {
    // ---- use regex to check if user inputted email is acceptable
    const emailCheckReturn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(userInput.email);

    if (validationType === "login") {
      // ---- User input validation
      // ---- check if inputs are empty
      if (!userInput.email || !userInput.password) {
        states.setErrorText("Please fill all fields");
        return;
      }

      // ---- check if email follows conventional regex
      if (!emailCheckReturn) {
        states.setErrorText("Please enter a valid email address");
        return;
      }

      // ---- check if password is longer than 4 characters
      if (userInput.password.length <= passwordVariables.minPassLength) {
        states.setErrorText(
          `Please enter a password with a length of ${
            passwordVariables.minPassLength + 1
          } characters or longer`
        );
        return;
      }
      await loginUser(userInput, states.setErrorText);
    } else if (validationType === "signup") {
      // ---- User input validation
      // ---- check if inputs are empty
      if (
        !userInput.firstName ||
        !userInput.lastName ||
        !userInput.email ||
        !userInput.password ||
        !userInput.confirmPassword
      ) {
        states.setErrorText("Please fill all fields");
        return;
      }

      // ---- check if email follows conventional regex
      if (!emailCheckReturn) {
        states.setErrorText("Please enter a valid email address");
        return;
      }

      // ---- check if password and confirm password are the same
      if (userInput.password !== userInput.confirmPassword) {
        states.setErrorText("Password does not match");
        return;
      }

      // ---- check if password is longer than 9 characters
      if (userInput.password.length <= passwordVariables.minPassLength) {
        states.setErrorText(
          `Please enter a password with a length of ${
            passwordVariables.minPassLength + 1
          } characters or longer`
        );
        return;
      }

      // ---- check if password has at least one number
      if (passwordVariables.numbers) {
        const checker = /\d/.test(userInput.password);
        if (!(await checker)) {
          states.setErrorText(`Please have at least one number in your password`);
          return;
        }
      }

      // ---- check if password has at least one letter
      if (passwordVariables.letters) {
        const checker = /[a-zA-Z]/.test(userInput.password);
        if (!(await checker)) {
          states.setErrorText(`Please have at least one letter in your password`);
          return;
        }
      }

      // ---- check if password has at least one special character
      if (passwordVariables.specialCharacter) {
        const checker = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(userInput.password);
        if (!checker) {
          states.setErrorText(`Please have at least one special character in your password`);
          return;
        }
      }

      // ---- sends information to backend to create a new user and check if any errors occur
      await signupUser(userInput, states.setErrorText);
    } else if (validationType === "forgotPasswordUpdate") {
      // ---- User input validation
      if (!userInput.password || !userInput.confirmPassword) {
        states.setErrorText("Please fill all fields");
        return "validation failed";
      }

      // ---- check if password and confirm password are the same
      if (userInput.password !== userInput.confirmPassword) {
        states.setErrorText("Password does not match");
        return "validation failed";
      }

      // ---- check if password is longer than 9 characters
      if (userInput.password.length <= passwordVariables.minPassLength) {
        states.setErrorText(
          `Please enter a password with a length of ${
            passwordVariables.minPassLength + 1
          } characters or longer`
        );
        return "validation failed";
      }

      // ---- check if password has at least one number
      if (passwordVariables.numbers) {
        const checker = /\d/.test(userInput.password);
        if (!checker) {
          states.setErrorText(`Please have at least one number in your password`);
          return "validation failed";
        }
      }

      // ---- check if password has at least one letter
      if (passwordVariables.letters) {
        const checker = /[a-zA-Z]/.test(userInput.password);
        if (!checker) {
          states.setErrorText(`Please have at least one letter in your password`);
          return "validation failed";
        }
      }

      // ---- check if password has at least one special character
      if (passwordVariables.specialCharacter) {
        const checker = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(userInput.password);
        if (!checker) {
          states.setErrorText(`Please have at least one special character in your password`);
          return "validation failed";
        }
      }

      // ---- sends information to backend to update user password and check if any errors occur
      return await requestPassRecovUpdate(
        userInput.password,
        userInput.email,
        userInput.tempToken,
        states.setErrorText
      );
    }
  };

  getUser() {
    return decode(this.getToken());
  }

  logoutUser() {
    localStorage.removeItem("userToken");
    window.location.assign("/");
    localStorage.removeItem("lastUserActivityTimestamp");
  }

  getToken() {
    return localStorage.getItem("userToken");
  }

  loggedIn() {
    const token = this.getToken();
    if (token !== null) {
      window.addEventListener("click", this.isTokenActive());
      window.addEventListener("mousemove", this.isTokenActive());
      window.addEventListener("keypress", this.isTokenActive());
      window.addEventListener("scroll", this.isTokenActive());
      this.isTokenActive();
      return !!(token && !this.isTokenExpired(token));
    }
  }

  isTokenActive() {
    const tokenTimeoutMS = tokenTimeoutMinutes * 60 * 1000;
    const startTimeKey = "lastUserActivityTimestamp";

    localStorage.setItem(startTimeKey, Date.now());
    setTimeout(() => {
      const start = localStorage.getItem(startTimeKey);
      const timer = Date.now() - start;

      // if time exceeds the token timeout, then logout, return to home
      if (timer > tokenTimeoutMS) {
        this.logoutUser();
      }
    }, tokenTimeoutMS);
  }

  isTokenExpired(token) {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    return false;
  }
}

export default new AuthService();
