import Auth from "./auth";

const DEV_USER = process.env.USER || process.env.LOGNAME || process.env.USERNAME || "unknown";
const SERVER_HOSTNAME = process.env.REACT_APP_HOSTNAME || "frontend-local:" + DEV_USER;

const logError = async (
  JWT,
  {
    errorType,
    errorDetails,
    insurerName = "none",
    host = `${SERVER_HOSTNAME}/${window.location.hostname}`,
    userId = Auth.getUser()?.data?.id
  }
) => {
  try {
    const response = await fetch("/api/errors/log", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${JWT}`
      },
      // TODO: errorLogger could figure out the file name and line number that called it, and pass that along as well
      body: JSON.stringify({
        userId,
        insurerName,
        host,
        errorType,
        errorDetails
      })
    });

    if (!response.ok) {
      console.error("Failed to log error to database");
    }
  } catch (err) {
    console.error("Error while logging error:", err);
  }
};

// Convenience method
const toErrorsDb = async (insurerName, errorType, errorDetails) =>
  logError(Auth.getToken(), {
    errorType,
    errorDetails,
    insurerName
  });

export { logError, toErrorsDb };
