import React, { useState, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { FaLink, FaFileExcel } from "react-icons/fa";
import { toast } from "react-hot-toast";
import Auth from "../../utils/auth";
import Sidebar from "../../components/sidebar/Sidebar";
import DashboardHeader from "../../components/dashboardHeader/DashboardHeader";
import errorsFetch from "../../useFetch/errorsFetch/errorsFetch";
import Button from "../../components/button/Button";
import Table from "../../components/table/table";

const defaultErrorsQuery =
  // default query, with ability to customize the columns displayed
  `SELECT created_at, user_id, insurer_name, host, error_type, error_details FROM errors 
WHERE created_at >= NOW() - INTERVAL 24 HOUR
ORDER BY id DESC;`;

const defaultNdcProductsQuery = `SELECT * FROM ndc_products LIMIT 10;`;
const defaultNdcPackagesQuery = `SELECT * FROM ndc_packages LIMIT 10;`;

const chooseDefaultQuery = () => {
  const path = window.location.pathname;
  if (path.includes("errors")) return defaultErrorsQuery;
  if (path.includes("ndcPackages")) return defaultNdcPackagesQuery;
  return defaultNdcProductsQuery;
};

const chooseTitle = () => {
  const path = window.location.pathname;
  if (path.includes("errors")) return "Error Logs Query";
  if (path.includes("ndcPackages")) return "NDC Packages Query";
  return "NDC Products Query";
};

const chooseSubtitle = () => {
  const path = window.location.pathname;
  if (path.includes("errors")) return "Query the errors database to investigate issues.";
  if (path.includes("ndcPackages")) return "Query NDCs by package and dosage.";
  return "Query NDCs by name and product info.";
};

// map of column names to their preferred width in the table
const columnWidths = {
  id: { minWidth: "3rem", width: "5%" },
  user_id: { minWidth: "3rem", width: "5%" },
  insurer_name: { minWidth: "6rem", width: "10%" },
  host: { minWidth: "6rem", width: "10%" },
  error_type: { minWidth: "6rem", width: "10%" },
  error_details: { minWidth: "30rem", width: "60%" },
  created_at: { minWidth: "3rem", width: "5%" }
};

export default function Errors() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialErrorsQuery = searchParams.get("query") || chooseDefaultQuery();

  const [query, setQuery] = useState(initialErrorsQuery);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc"
  });

  // Add useEffect to execute query on component mount
  useEffect(() => {
    const fetchInitialData = async () => {
      const { myResults, myError } = await errorsFetch.executeQuery(query);
      setResults(myResults);
      setError(myError);
    };

    fetchInitialData();
  }, []); // Empty dependency array means this runs once on mount

  // Update URL when query changes
  const updateQueryParam = (newQuery) => {
    setSearchParams({ query: newQuery });
  };

  // Handle query changes
  const handleQueryChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    updateQueryParam(newQuery);
  };

  // Create permalink
  const getPermalink = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("query", query);
    return url.toString();
  };

  // Copy permalink to clipboard
  const copyPermalink = () => {
    navigator.clipboard.writeText(getPermalink());
    toast.success("Permalink copied to clipboard!");
  };

  // Sorting function
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Get sorted data
  const getSortedData = () => {
    if (!results || !sortConfig.key) return results;

    return [...results].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const formatValue = (value) => {
    if (typeof value === "object") {
      return <pre>{JSON.stringify(value, null, 2)}</pre>;
    }
    if (typeof value === "string" && value.includes("{")) {
      try {
        return <pre>{JSON.stringify(JSON.parse(value), null, 2)}</pre>;
      } catch {
        return value; // Return original value if JSON parsing fails
      }
    }
    if (typeof value === "string" && value.includes("\\n")) {
      return <pre>{value.replace(/\\n/g, "\n")}</pre>;
    }
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { myResults, myError } = await errorsFetch.executeQuery(query);
    setResults(myResults);
    setError(myError);
  };

  // Add function to handle Excel export
  const exportToExcel = () => {
    if (!results || results.length === 0) {
      toast.error("No data to export");
      return;
    }

    // Convert data to CSV format
    const headers = Object.keys(results[0]);
    const csvContent = [
      headers.join(","), // Header row
      ...results.map((row) =>
        headers
          .map((header) => {
            const value = row[header];
            // Handle special cases (objects, null, undefined)
            if (typeof value === "object") {
              return `"${JSON.stringify(value)
                .replace(/"/g, '""')
                .replace(/,/g, ";")
                .replace(/[\r\n]+/g, " ")}"`;
            }
            if (!value) return "";
            // Replace commas with semicolons and newlines with spaces
            return value.replace(/,/g, ";").replace(/[\r\n]+/g, " ");
          })
          .join(",")
      )
    ].join("\n");

    // Create and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "error_logs.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.success("Export started");
  };

  if (!Auth.loggedIn()) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen bg-grayColor">
      <Sidebar>
        <DashboardHeader withBackButton={false} title={chooseTitle()}>
          <p className="m-0 text-grayColor">{chooseSubtitle()}</p>
        </DashboardHeader>

        <div className="p-4">
          <div className="mb-4 text-sm text-gray-600">
            Debug Tools:{" "}
            <a href="/errors" className="text-blue-500 hover:text-blue-600">
              Errors Page
            </a>
            {", "}
            <a href="/ndcProducts" className="text-blue-500 hover:text-blue-600">
              NDC Products
            </a>
            {", "}
            <a href="/ndcPackages" className="text-blue-500 hover:text-blue-600">
              NDC Packages
            </a>
            {", "}
            <a href="/rxlink" className="text-blue-500 hover:text-blue-600">
              RxLink Page
            </a>
          </div>
          <form onSubmit={handleSubmit} className="mb-4 mt-0">
            <textarea
              value={query}
              onChange={handleQueryChange}
              className="w-full p-2 border rounded mb-2 font-mono"
              rows={4}
            />
            <div className="flex justify-between items-center">
              <Button type="submit">Execute Query</Button>
              <div className="flex gap-2">
                <a
                  href="https://docs.google.com/document/d/1vAuWib08A2Q_hp5qhqDtuJExttFI0grLat6FIjlnTYg"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-600 flex items-center">
                  <FaLink className="mr-1" /> Common Queries
                </a>
                <button
                  type="button"
                  onClick={exportToExcel}
                  className="text-green-600 hover:text-green-700 flex items-center">
                  <FaFileExcel className="mr-1" /> Export to Excel
                </button>
                <button
                  type="button"
                  onClick={copyPermalink}
                  className="text-blue-500 hover:text-blue-600 flex items-center">
                  <FaLink className="mr-1" /> Copy Permalink
                </button>
              </div>
            </div>
          </form>

          {error && <div className="text-red-500 mb-4"> Error: {error} </div>}

          {results && results.length > 0 && (
            <div className="w-full overflow-hidden">
              <div className="text-gray-600 mb-2">
                Query returned {results.length} result{results.length === 1 ? "" : "s"}
              </div>
              <div className="max-w-full overflow-hidden">
                <Table
                  className="table-fixed w-full"
                  headers={Object.keys(results[0]).map((header, index) => (
                    <div
                      key={index}
                      className="cursor-pointer select-none relative group max-w-full"
                      style={columnWidths[header]}
                      onClick={() => handleSort(header)}>
                      <div className="flex items-center justify-between">
                        {header}
                        {sortConfig.key === header && (
                          <span className="ml-1">{sortConfig.direction === "asc" ? "↑" : "↓"}</span>
                        )}
                      </div>
                    </div>
                  ))}
                  data={getSortedData()?.map((row) =>
                    Object.values(row).map((value, index) => (
                      <div key={index} className="break-all whitespace-pre-wrap max-w-full">
                        {formatValue(value)}
                      </div>
                    ))
                  )}
                />
              </div>
            </div>
          )}

          {results && results.length === 0 && (
            <div className="text-gray-500 text-center py-4">No results found.</div>
          )}
        </div>
      </Sidebar>
    </div>
  );
}
