/* eslint-disable react/jsx-key */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import { useBreakpoints } from "react-breakpoints-hook";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import patientFetches from "../../useFetch/userFetch/patientFetches";
import Auth from "../../utils/auth";
import { makeFirstLetterCapital } from "../../utils/dataFormatHelpers";
import formatDateUtil from "../../utils/formatDate";
import formatMoneyUtil from "../../utils/formatMoney";
import RxHistorySavingsModal from "../RxHistorySavingsModal/RxHistorySavingsModal";
import Button from "../button/Button";
import LoadingSkeleton from "../loadingSkeleton/loadingSkeleton";
import Table from "../table/table";
import Tooltip from "../tooltips/tooltipLogic";
import tooltipObject from "../tooltips/tooltipObject";
import DashboardExhibitContainer from "./dashboardExhibitContainer";
import EmptyState from "../dashboardEmptyState/emptyState";
import { toErrorsDb } from "../../utils/errorLogger";

export default function RxHistoryTable({ setFetchStatus, dashboardView }) {
  const [patientDrugList, setPatientDrugList] = useState([]);
  const [activeTooltip, setActiveTooltip] = useState(false);
  const [drugTooltip, setDrugTooltip] = useState(false);
  const [costDetailsTooltip, setCostDetailsTooltip] = useState(false);
  const [priceComparisonTooltip, setPriceComparisonTooltip] = useState(false);
  const [loadCount, setLoadCount] = useState(5);
  const [status, setStatus] = useState("loading");
  const [renderedPatientDrugList, setRenderedPatientDrugList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  // state for modal object
  const [modalBreakdownInfo, setModalBreakdownInfo] = useState({
    drugName: "",
    drugNDC: "",
    patientPaid: null,
    brandBP: null,
    brandSource: "",
    brandDrugName: "",
    genericBPs: null,
    genericSource: "",
    genericDrugName: ""
  });
  const loadMore = () => {
    Mixpanel("Rx History Load More");
    ReactGATracking("Button Click", "Rx History Load More");
    ReactPixelInitialize("Rx History Load More", { value: "none" });
    // Increase the count by 5 on each click
    setLoadCount((previousCount) => previousCount + 5);
  };

  const { sm } = useBreakpoints({
    sm: { min: 0, max: 640 }
  });

  // conditionally render price comparison message based on different outcomes
  const renderSavingsAmount = (drug) => {
    if (!drug) {
      toErrorsDb("rxHistory", "DRUG_PRICE_INVALID", {
        drug: "" + drug
      });
    }

    // TODO: if brandSavings AND genericSavings are both zero/negative/null:
    //       --> you are already paying the best price
    // Otherwise: yourSavings = max(brandSavings, genericSavings)
    //       --> you may be overpaying by [yourSavings]
    // Could probably define a helper called "nullSafeMax(brandSavings, genericSavings)"
    // and then check if that's positive, and that's all you would need to check

    // if there's brand and generic savings and brand savings are more
    if (drug.brandSavings && drug.genericSavings && drug.brandSavings > drug.genericSavings) {
      return (
        <p className="text-dangerColor text-lg m-0">
          You may be overpaying by {formatMoneyUtil.formatMoney(drug.brandSavings)}!
        </p>
      );
      // if there's brand and generic savings and generic savings are more
    }
    if (drug.brandSavings && drug.genericSavings && drug.brandSavings < drug.genericSavings) {
      return (
        <p className="text-dangerColor text-lg m-0">
          You may be overpaying by {formatMoneyUtil.formatMoney(drug.genericSavings)}!
        </p>
      );
      // if there's no generic savings and brand savings is positive
    }
    if (!drug.genericSavings && drug.brandSavings > 0) {
      return (
        <p className="text-dangerColor text-lg m-0">
          You may be overpaying by {formatMoneyUtil.formatMoney(drug.brandSavings)}!
        </p>
      );
      // if there's no brand savings and generic savings is positive
    }
    if (!drug.brandSavings && drug.genericSavings > 0) {
      return (
        <p className="text-dangerColor text-lg m-0">
          You may be overpaying by {formatMoneyUtil.formatMoney(drug.genericSavings)}!
        </p>
      );
    }
    return (
      <span className="text-successColor font-semibold">
        You are already paying the best price!
      </span>
    );
  };

  // this function is ran when "Breakdown" or "Breakdown & Coupon" is clicked
  const setBreakdown = (drug) => {
    Mixpanel("RxHistory Breakdown Modal");
    ReactGATracking("Button Click", "RxHistory Breakdown Modal");
    ReactPixelInitialize("RxHistory Breakdown Modal", { value: "none" });
    setModalBreakdownInfo({
      drugName: drug.drug_name,
      drugNDC: drug.drug_ndc,
      patientPaid: parseInt(drug.patient_paid, 10),
      brandBP: parseInt(drug.brandDrugBP, 10),
      brandSource: drug.bestPriceBrandSource,
      brandDrugName: drug.brandDrugName,
      genericBP: parseInt(drug.genericDrugBP, 10),
      genericSource: drug.bestPriceGenericSource,
      genericDrugName: drug.genericDrugName,
      brandSavings: drug.brandSavings || null,
      genericSavings: drug.genericSavings || null
    });
    setOpenModal(true);
  };

  useEffect(() => {
    (async function initialRxHistoryFetch() {
      if (Auth.loggedIn()) {
        const userToken = Auth.getToken();
        try {
          const patientDrugListFetch = await patientFetches.getPatientDrugs(userToken);

          if (patientDrugListFetch.length === 0) {
            localStorage.setItem("drugListStatus", "null");
            setStatus("missing");
            return;
          }

          setPatientDrugList(patientDrugListFetch);
          setRenderedPatientDrugList(patientDrugListFetch.slice(0, loadCount));

          setFetchStatus((prevState) => ({
            ...prevState,
            rxHistory: "success"
          }));
          setStatus("complete");
        } catch (err) {
          console.error(err);
          setFetchStatus((prevState) => ({
            ...prevState,
            rxHistory: "failed"
          }));
          setStatus("failed");
        }
      }
    })();
  }, []);

  useEffect(() => {
    setRenderedPatientDrugList(patientDrugList.slice(0, loadCount));
  }, [loadCount]);

  const renderTableData = () => {
    if (status === "loading") {
      const loadingSkeletonArray = new Array(3);
      loadingSkeletonArray.fill([
        <LoadingSkeleton skeletonRenderCount={4} className="my-3" key="1" />,
        <LoadingSkeleton skeletonRenderCount={3} className="my-3" key="2" />
      ]);
      return loadingSkeletonArray;
    }
    if (status === "complete") {
      const drugListToMapThrough = dashboardView
        ? renderedPatientDrugList.slice(0, 3)
        : renderedPatientDrugList;
      return drugListToMapThrough.map((drug, index) => [
        <div key={index}>
          Name: {makeFirstLetterCapital(drug.drug_name)}
          {!dashboardView && (
            <>
              <br />
              <span className="text-lightGrayColor">(NDC: {drug.drug_ndc})</span>
            </>
          )}
          <br />
          Prescribed:{" "}
          {drug.date_prescribed ? formatDateUtil.formatDate(drug.date_prescribed) : "N/A"}
          <br />
          {!dashboardView && <>Prescriber: {drug.provider ? drug.provider : "N/A"}</>}
        </div>,
        <div>
          {!drug.patient_paid && !drug.insurer_paid && !drug.total_price ? (
            <p className="text-lg m-0">No cost information available</p>
          ) : (
            <>
              Patient paid:{" "}
              {drug.patient_paid ? formatMoneyUtil.formatMoney(drug.patient_paid) : "-"}
              <br />
              Insurer paid:{" "}
              {drug.insurer_paid ? formatMoneyUtil.formatMoney(drug.insurer_paid) : "-"}
              <br />
              <hr style={{ width: "12rem", margin: "0.25rem 0rem" }} />
              Total price: {drug.total_price ? formatMoneyUtil.formatMoney(drug.total_price) : "-"}
            </>
          )}
        </div>,
        (!drug.brandDrugBP && !drug.genericDrugBP) ||
        (drug.brandSavings <= 0 && drug.genericSavings <= 0) ? (
          <div>
            <p className="text-successColor text-lg m-0">You are already paying the best price!</p>
            <Button
              className="text-mainTextColor text-lg underline m-0 cursor-pointer"
              padding="0"
              theme="noStyle"
              onClick={() => setBreakdown(drug)}>
              Breakdown
            </Button>
          </div>
        ) : (
          <div>
            {/* check patient paid against brand AND generic and present best savings */}
            {renderSavingsAmount(drug)}
            <Button
              className="text-mainTextColor text-lg underline m-0 cursor-pointer"
              padding="0"
              theme="noStyle"
              onClick={() => setBreakdown(drug)}>
              Breakdown & Coupon
            </Button>
          </div>
        )
      ]);
    }
    return null;
  };

  return (
    <>
      <RxHistorySavingsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalBreakdownInfo={modalBreakdownInfo}
        closeButtonClicked={() => setOpenModal(false)}
      />
      <DashboardExhibitContainer
        title={
          <div className="whitespace-nowrap">
            Rx History
            <FaRegCircleQuestion
              className="text-sm mb-2 ml-2"
              onMouseOver={() => setActiveTooltip(true)}
              onMouseLeave={() => setActiveTooltip(false)}
            />
            {activeTooltip && (
              <Tooltip
                className="tooltipContainer flex items-center"
                text={tooltipObject.rxHistoryTitle}
                size={sm ? "beneathInline" : "small"}
              />
            )}
          </div>
        }
        subtitle={
          status === "complete" && "A list of your rx history and the cost details associated."
        }
        viewAll={dashboardView}
        viewAllCount={renderedPatientDrugList.length}
        viewAllParam="/rxHistory">
        {status === "complete" && (
          <Table
            tableDivClass="universalTableDivClass"
            tableClass={`${
              patientDrugList.length === 0 ? "noDataHeaders" : ""
            } universalTable rxHistoryMobile`}
            headers={[
              <div>
                Drug Information
                <FaRegCircleQuestion
                  className="text-sm ml-2"
                  onMouseOver={() => setDrugTooltip(true)}
                  onMouseLeave={() => setDrugTooltip(false)}
                />
                {drugTooltip && (
                  <Tooltip
                    className="tooltipContainer flex items-center"
                    text={tooltipObject.drugHeader}
                    size={sm ? "beneathInline" : "small"}
                  />
                )}
              </div>,
              <div>
                Cost Details
                <FaRegCircleQuestion
                  className="text-sm ml-2"
                  onMouseOver={() => setCostDetailsTooltip(true)}
                  onMouseLeave={() => setCostDetailsTooltip(false)}
                />
                {costDetailsTooltip && (
                  <Tooltip
                    className="tooltipContainer flex items-center"
                    text={tooltipObject.costDetailsHeader}
                    size={sm ? "beneathInline" : "small"}
                  />
                )}
              </div>,
              <div className="whitespace-nowrap">
                Price Comparison
                <FaRegCircleQuestion
                  className="text-sm ml-2"
                  onMouseOver={() => setPriceComparisonTooltip(true)}
                  onMouseLeave={() => setPriceComparisonTooltip(false)}
                />
                {priceComparisonTooltip && (
                  <Tooltip
                    className="tooltipContainer flex items-center"
                    text={tooltipObject.priceComparisonHeader}
                    size="extra-large"
                  />
                )}
              </div>
            ]}
            data={renderTableData()}
          />
        )}
        {renderedPatientDrugList.length < patientDrugList.length &&
          status === "complete" &&
          !dashboardView && (
            <Button
              className="rounded-b-md rounded-t-none text-base border-none"
              fullWidth
              onClick={loadMore}>
              Load more...
            </Button>
          )}
        {status === "missing" && (
          <EmptyState title="Rx History" onClick={() => navigate("/insuranceSelect")} />
        )}
        {status === "failed" && (
          <p className="m-0 p-2 text-xl">
            Something went wrong with fetching your Rx History. Please try again later.
          </p>
        )}
      </DashboardExhibitContainer>
    </>
  );
}
