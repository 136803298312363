// import { FiSearch } from "react-icons/fi"; // Feather Icons
import { AiOutlineSearch } from "react-icons/ai";
import React from "react";
import Button from "../button/Button";

export default function EmptyState({ title, onClick }) {
  return (
    <div className="flex flex-col items-center justify-center text-gray-500 pb-4">
      <AiOutlineSearch className="text-7xl mb-2" />
      <p className="m-2 text-xl font-bold text-blackTextColor">No {title} found</p> {/* Header */}
      <p className="m-2 text-mediumGrayTextColor text-base">
        Add data by linking your provider
      </p>{" "}
      {/* Subheader */}
      <Button
        onClick={onClick}
        type="button"
        className="mt-4 px-4 py-2 bg-thirdAccentColor text-white rounded">
        Link Insurance Company
      </Button>
    </div>
  );
}
