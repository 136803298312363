import pollingHelpers from "../../utils/pollingHelpers";
import { logError } from "../../utils/errorLogger";

// check poll status
const pollData = async (pollingEventID, JWT) => {
  const millimanData = await pollingHelpers.initiatePolling(pollingEventID, JWT);
  if (millimanData.status === "failed" || millimanData.status === "missing") {
    return millimanData.status;
  }

  const allResults = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const result of millimanData.response.result) {
    if (result.Value && result.Value.rows && result.Value.rows.length) {
      allResults.push(...result.Value.rows);
    }
  }

  return allResults;
};

// this function will trigger the milliman workflow when called upon
export default {
  trigger: async (JWT) => {
    try {
      const response = await fetch("/api/milliman/getAllPlanRanks", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${JWT}`
        }
      });

      if (response.ok) {
        const data = await response.json();

        return pollData(data.pollingEventID, JWT);
      }

      await logError(JWT, {
        errorType: "MILLIMAN_FETCH_ERROR",
        errorDetails: {
          status: response.status,
          statusText: response.statusText,
          endpoint: "/api/milliman/getAllPlanRanks"
        },
        insurerName: "milliman"
      });

      console.error("failed to trigger milliman workflow");
      return [];
    } catch (err) {
      await logError(JWT, {
        errorType: "MILLIMAN_FETCH_EXCEPTION",
        errorDetails: {
          message: err.message,
          stack: err.stack
        },
        insurerName: "milliman"
      });

      console.error("failed to trigger milliman workflow");
      return [];
    }
  },
  createMillimanContact: async (JWT, contactInfo) => {
    try {
      const response = await fetch("/api/milliman/createContact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${JWT}`
        },
        body: JSON.stringify(contactInfo)
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      }

      await logError(JWT, {
        errorType: "MILLIMAN_FETCH_ERROR",
        errorDetails: {
          status: response.status,
          statusText: response.statusText,
          endpoint: "/api/milliman/reTriggerPlanRanks"
        },
        insurerName: "milliman"
      });
      console.error("failed to trigger milliman workflow");
      return [];
    } catch (err) {
      await logError(JWT, {
        errorType: "MILLIMAN_FETCH_EXCEPTION",
        errorDetails: {
          message: err.message,
          stack: err.stack
        },
        insurerName: "milliman"
      });

      console.error("failed to trigger milliman workflow");
      return [];
    }
  },
  reTriggerPlanRanks: async (JWT) => {
    try {
      const response = await fetch("/api/milliman/reTriggerPlanRanks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${JWT}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      }

      await logError(JWT, {
        errorType: "MILLIMAN_FETCH_ERROR",
        errorDetails: {
          status: response.status,
          statusText: response.statusText,
          endpoint: "/api/milliman/reTriggerPlanRanks"
        },
        insurerName: "milliman"
      });
      console.error("failed to trigger milliman workflow");
      return [];
    } catch (err) {
      await logError(JWT, {
        errorType: "MILLIMAN_FETCH_EXCEPTION",
        errorDetails: {
          message: err.message,
          stack: err.stack
        },
        insurerName: "milliman"
      });

      console.error("failed to trigger milliman workflow");
      return [];
    }
  }
};
