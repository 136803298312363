import { useEffect, useState } from "react";

export default function useProgress(initialProgress = 0, speed = 100, maxProgress = 95) {
  const [progress, setProgress] = useState(initialProgress);

  useEffect(() => {
    let interval;
    if (progress < maxProgress) {
      interval = setInterval(() => {
        setProgress((prev) => (prev < maxProgress ? prev + 1 : prev)); // Increases by 1% every 100ms
      }, speed);
    }

    return () => clearInterval(interval);
  }, [progress, maxProgress, speed]);

  const resetProgress = () => setProgress(0);
  const completeProgress = () => setProgress(100);

  return { progress, resetProgress, completeProgress };
}
